import React from 'react';
import style from 'styled-components';

interface IPlaceholderProps {
    label: string;
}

const Placeholder = ({ label }: IPlaceholderProps) => {
    return (
        <PlaceholderStyle>{label}</PlaceholderStyle>
    );
};

export default Placeholder;

const PlaceholderStyle = style.p`
    text-align: center;
    margin: 32px;
    color: ${props => props.theme.onBackground};
    ${props => props.theme.information.desktop};
`;