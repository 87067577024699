import React from 'react';
import style from 'styled-components';
import {useTranslation} from "react-i18next";
import { Modal as ModalType } from '../../../../../components/Modal/type';
import { requestModalClosing, requestModalOpening } from '../../../../../components/Modal/utils';
import Button, { IButtonStyle } from '../../../../../components/Button';
import ErrorModal from './ErrorModal';
import { deleteAccount } from '../../../../../utils/authentication/useAuth';

const Modal: ModalType = ({ clickedOutside }) => {
  const {t} = useTranslation();
  if (clickedOutside) {
    requestModalClosing();
  }
  return (
    <Container>
      <Title>
        {t('account.profile.deletion.modal.heading')}
      </Title>
      <Action>
        {t('account.profile.deletion.modal.action')}
      </Action>
      <Information>
        {t('account.profile.deletion.modal.information')}
      </Information>
      <ButtonContainer>
        <Button 
          label={t('button.cancel')}
          handleClick={requestModalClosing}
        />
        <Button 
          label={t('button.confirm')}
          colorStyle={IButtonStyle.negative}
          handleClick={() => {
            // Delete account
            deleteAccount()?.then(() => {
              requestModalClosing();
              window.location.href = '/';
            }).catch(() => {
              requestModalClosing();
              requestModalOpening(ErrorModal);
            });
          }}
        />
      </ButtonContainer>
    </Container>
  );
}

const Container = style.div`
  background: ${({theme}) => theme.surface};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: ${({theme}) => theme.large.desktop};
  padding-bottom: ${({theme}) => theme.medium.desktop};
  max-width: 40vw;
`;

const Title = style.div`
  ${({theme}) => theme.heading.desktop}
  margin-bottom: ${({theme}) => theme.large.desktop};
  color: ${({theme}) => theme.onSurfacePure};
`;

const Action = style.div`
  ${({theme}) => theme.action.desktop}
  margin-bottom: ${({theme}) => theme.medium.desktop};
  color: ${({theme}) => theme.onSurface};
`;

const Information = style.div`
  margin-bottom: ${({theme}) => theme.large.desktop};
  color: ${({theme}) => theme.onBackground};
  ${({theme}) => theme.information.desktop}
`;

const ButtonContainer = style.div`
  display: flex;
  justify-content: space-between;
`;

export default Modal;