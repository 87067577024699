import React from 'react';
import style from 'styled-components';
import { useTranslation } from 'react-i18next';
import BoxGrid from '../BoxGrid';
import { Box as BoxType } from '../../utils/types/type';

interface SearchBoxGridProps {
    boxes: BoxType[],
    searchString: string,
}

const SearchBoxGrid: React.FC<SearchBoxGridProps> = ({ boxes, searchString, children }) => {
    const { t } = useTranslation()
    let filtered = boxes.filter(box => makeNormalizedString(box.name).includes(makeNormalizedString(searchString)))

    // Render
    if (searchString && searchString.length > 0) {
        if (filtered.length > 0) {
            return (
                <Spacing>
                    <BoxGrid keyPrefix="search" boxes={filtered} />
                </Spacing>
            )
        } else {
            return (
                <Spacing>
                    <Message>{t('home.search.empty')}</Message>
                </Spacing>
            )
        }
    } else {
        return <React.Fragment />
    }
}

export default SearchBoxGrid;

const makeNormalizedString = (str: string) => str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

const Spacing = style.div`
    margin-top: 18px;
`

const Message = style.div`
    color: #767676;
    font-size: 16px;
    text-align: center;
`