import React from 'react';
import style from 'styled-components';
import {
  Switch,
  useRouteMatch
} from "react-router-dom";

import MainObject from '../../components/MainObject';
import Menu from './Menu';
import subPages from './SubPages';
import generateRoutes from '../../utils/routes/generateRoutes';

const Account: () => JSX.Element = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path } = useRouteMatch();
  return (
    <MainObject>
        <CenterContainer>
          <Switch>
            {generateRoutes(subPages(path))}
          </Switch>
        </CenterContainer>
        <RightContainer>
          <Menu/>
        </RightContainer>
    </MainObject>
  );
};
const LeftContainer = style.div`
`;

const CenterContainer = style.div`
`;

const RightContainer = style.div`
`;

export default Account;