import * as React from 'react';
import { Link, useHistory } from "react-router-dom";
import UserCircle from "../Avatar";
import Button from "../Button";
import Icon from "../IconDeprecated";
import { useTranslation } from 'react-i18next';
import style from 'styled-components';
import { IconType } from '../Icon';

interface INavbarActionsProps {
    isAuth: boolean;
    hasOrganisation: boolean;
    initials?: string;
    name: string;
}

const NavbarActions = ({ isAuth, hasOrganisation, initials, name }: INavbarActionsProps) => {
    const history = useHistory();
    const { t } = useTranslation();

    if (!isAuth) {
        return <Button label={t('general.navbar.connect')} handleClick={() => history.push('/login')}
            iconLeft={IconType.person} />
    }

    return <AuthActionContainer>
        {hasOrganisation &&
            <>
                <Organisation />
                <VerticalSeparator />
            </>}
        {initials && <StyleLink><Link to={"/user"}><UserCircle initials={initials} name={name} /></Link></StyleLink>}
    </AuthActionContainer>
};

const StyleLink = style.div`
    a {
        text-decoration: none;
        color: black;
    }
`;

const AuthActionContainer = style.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

// TODO : component to review after retrieve organisation info
const Organisation = style.div`
    width: 50px;
    height: 50px;
    background-color: #B0B0B0;
`;

const VerticalSeparator = style.span`
    margin: 0 24px;
    border-left: 2px solid #F4F4F4;
    height: 28px;
`;

export default NavbarActions;