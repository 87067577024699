import React, { useContext, useEffect, useState } from 'react';
import style from 'styled-components';
import MainObject from "../../components/MainObject";
import BoxIdeasList from "../../components/BoxIdeasList";
import { loadIdeasFromBox, useFilteredAndSortedIdeas, useListenerToBox } from "../../utils/firebase/useFilteredIdeas";
import { useParams } from "react-router";
import Desc from './Desc';
import { Box, Idea } from './../../utils/types/type';
import { getBox } from '../../utils/repositories/boxRepository';
import Breadcrumb from '../../components/Breadcrumb/BreadcrumbIdea';
import { NavigationContext } from '../../utils/contexts/NavigationContext';
import Placeholder from '../../components/Placeholder';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../utils/contexts/UserContext';
import { getIdea, viewIdea } from '../../utils/repositories/IdeaRepository';
import { IdeaContentCard } from '../../components/Card/IdeaContentCard';
import SectionTitle from '../../components/SectionTitle';
import CommentSection from '../../components/CommentSection';
import { listenToDocument } from '../../utils/database/databaseRepository';
import { mapDataToIdea } from '../../utils/mapping/IdeaMapper';

interface Params {
    id: string,
}

const IdeaPage: () => JSX.Element = () => {
    const { id } = useParams<{ id: string }>(); // idea id in the url
    const { user } = useContext(UserContext);
    const [idea, setIdea] = useState<Idea | undefined>();
    // const [box, setBox] = useState<Box>();
    const { t } = useTranslation();

    const { setNavBarTitle } = useContext(NavigationContext);

    // const { ideas, error } = useFilteredAndSortedIdeas();

    useEffect(() => {
        // increment idea views
        viewIdea(id);
    }, [id]);

    useEffect(() => {
        if (user && id.length > 0) {
            listenToDocument('ideas', id, (ideaDatabase) => {
                const i = mapDataToIdea(ideaDatabase);
                setIdea(i);
                setNavBarTitle(i.title)
            });
            /*
            getIdea(id).then(i => {
                setIdea(i);
                setNavBarTitle(i.title);
            });*/
            // loadIdeasFromBox(id, user.id);
            // getBox(id).then(b => {
            //     setNavBarTitle(b.name);
            //     setBox(b)
            // })
        }
    }, [id, setNavBarTitle, user]);

    // const [box, updateBox] = React.useState<BoxType>();
    // const orgaName = (box !== undefined) ? box.organization?.name : "";
    // const orgaId = (box !== undefined) ? box.organization?.id : "";
    // const boxName = (box !== undefined) ? box.name : "";
    // const loading = (box == undefined)

    return idea ? (
        <MainObject>
            <LeftContainer>
            </LeftContainer>
            <CenterContainer>
                <Breadcrumb loading={idea === undefined} organisationLabel={idea ? idea.organization.name : ''} organisationLink={`../o/${idea ? idea.organization.id : ''}`} boxLabel={idea ? idea.box.title : ''} boxLink={`../b/${idea ? idea.box.id : ''}`} />
                <Divider />
                <IdeaContentCard idea={idea} />
                <Divider />
                <SectionTitle>{t("idea.detail.comment.title")}</SectionTitle>
                <CommentSection idea={idea} />
            </CenterContainer>
            <RightContainer>
                <Divider />
                <Desc idea={idea} user={user || undefined} />
            </RightContainer>
        </MainObject>
    ) : (
            <MainObject>
                <LeftContainer>
                </LeftContainer>
                <CenterContainer>
                    <Placeholder label={t('idea.error.not-found')} />
                </CenterContainer>
                <RightContainer>
                </RightContainer>
            </MainObject>
        );
};

const LeftContainer = style.div`
`;

const CenterContainer = style.div`
    width: 692px;
    padding-bottom: 36px;
`;

const RightContainer = style.div`
    margin-top: -8px !important;
`;

const Divider = style.div`
    height: 24px;
`;

export default IdeaPage;