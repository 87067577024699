import React from 'react';
import style from 'styled-components';
import Container from './Container';
import Action from './ActionButton';
import { Link } from "react-router-dom";
import { IconType } from '../Icon';

interface BoxProps {
    title: string,
    desc: string,
    id: string,
    nbOfLikes: number,
    nbOfIdeas: number,
    nbOfComments: number
}

const ListBoxCard: React.FC<BoxProps> = ({ title, desc, id, nbOfLikes, nbOfIdeas, nbOfComments }): JSX.Element => {
    return (
        <FlexWrapper>
            <Container>
                <ContentWrapper>
                    <CustomLink to={`/b/${id}`}>
                        <Title>{title}</Title>
                        <Text>{desc}</Text>
                        <ActionContainer>
                            <Action icon={IconType.bulb} number={`${nbOfIdeas}`}></Action>
                            <Action icon={IconType.comment} number={`${nbOfComments}`}></Action>
                            <Action icon={IconType.heart} number={`${nbOfLikes}`}></Action>
                        </ActionContainer>
                    </CustomLink>
                </ContentWrapper>
            </Container>
        </FlexWrapper>
    );
};

export default ListBoxCard;

const FlexWrapper = style.div`
    flex-basis: 49%
`;

const ContentWrapper = style.div`
  padding: 24px 36px;
  cursor: pointer;
  margin-bottom: 24px;
  background-color: ${props => props.theme.surface};
  border-radius: 6px;
`;

const Title = style.div`
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  /* identical to box height */

  letter-spacing: 0.2px;
  margin-bottom: 12px;
`;

const Text = style.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 12px;
  /* or 22px */

  letter-spacing: 0.2px;

  /* Secondary Deep */

  color: #767676;

  /* 3 lines break */
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  min-height: 70px;
`;

const ActionContainer = style.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const CustomLink = style(Link)`
    text-decoration: none;
`;