import { useState, useEffect } from 'react';
import { Subject, BehaviorSubject } from 'rxjs';
import { Idea } from '../types/type';
import { listenToIdeasList } from '../repositories/IdeaRepository';
import { stringify } from 'uuid';

export type Error = string | null;

export type Filter = (ideas: Idea[]) => Idea[];
export type FilterHOC<Args = any> = (args: Args) => Filter;

const defaultSort: Filter = (ideas) => ideas;
const containSort: FilterHOC<string> = (search) => (ideas) => ideas.filter((a) => a.body.includes(search) || a.title.includes(search));
const dateSort: Filter = (ideas) => [...ideas].sort((a, b) => b.creationDate.valueOf() - a.creationDate.valueOf());
const likeSort: Filter = (ideas) => [...ideas].sort((a, b) => b.statistics.nbOfLikes - a.statistics.nbOfLikes);
// const activeSort:Filter = (ideas) => [...ideas].sort(a => a.status?.label == 'active');
// Doesn't work for whatever reasons...
// const unactiveSort:Filter = (ideas) => [...ideas].sort((a, b) => b.status - a.status);

export const sorts = {
    defaultSort,
    containSort,
    dateSort,
    likeSort,
    // activeSort,
    // unactiveSort,
};

const ideasArray: Idea[] = [];
const ideasSubject: BehaviorSubject<Idea[]> = new BehaviorSubject<Idea[]>(ideasArray);
ideasSubject.next(ideasArray);

const currentSorts: Filter[] = [dateSort];
const currentSortsSubject: Subject<Filter[]> = new Subject<Filter[]>();
currentSortsSubject.next(currentSorts);

const error: Error = null;
const errorSubject: Subject<Error> = new Subject<Error>();
errorSubject.next(error);

export const acknowledgeError = () => errorSubject.next(null);
export const updateSort = (v: Filter[]) => currentSortsSubject.next(v);

export const loadIdeasFromBox = (boxId: string, userId: string) => listenToIdeasList(boxId, userId, ideas => ideasSubject.next(ideas))

const pipe = (...fns: Filter[]) => (x: Idea[]) => fns.reduce((v, f) => f(v), x);

export const useListenerToBox: (boxId: string) => Idea[] = (boxId) => {
    const [ideas, updateIdeas] = useState<Idea[]>([]);
    useEffect(() => {
        const ideaSubscription = ideasSubject.subscribe({
            next: v => updateIdeas([...v]),
        });
        return () => ideaSubscription.unsubscribe();
    }, []);
    return ideas;
}

export const useFilteredAndSortedIdeas: () => { ideas: Idea[], error: Error }
    = () => {
        const [ideas, updateIdeas] = useState<Idea[]>(ideasArray);
        const [sorts, updateSorts] = useState<Filter[]>(currentSorts);
        const [error, updateError] = useState<Error>(null);
        useEffect(() => {
            const ideasSubscription = ideasSubject.subscribe({
                next: updateIdeas,
            });
            const filterSubscription = currentSortsSubject.subscribe({
                next: updateSorts,
            });
            const errorSubscription = errorSubject.subscribe({
                next: updateError,
            });
            return () => {
                ideasSubscription.unsubscribe();
                filterSubscription.unsubscribe();
                errorSubscription.unsubscribe();
            };
        }, []);

        return { ideas: pipe(...sorts)(ideas), error };
    }

/*
Filtrer par :
- Nom

Trier par :
- Date ;
- Likes ;

Trier par :
- Statut
*/