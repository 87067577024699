import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import style from 'styled-components';
import SectionTitle from "../../../components/SectionTitle";
import { UserContext } from "../../../utils/contexts/UserContext";
import Button, { IButtonAlign, IButtonStyle } from "../../../components/Button";
import { createOrganization, listenToUserOrganizations } from "../../../utils/repositories/organizationsRepository";
import { Organization } from "../../../utils/types/type";
import { ListCard } from "../../../components/Card/ListCard";
import Icon from "../../../components/IconDeprecated";
import Modal from "../../../components/Modal";
import { NavigationContext } from "../../../utils/contexts/NavigationContext";
import { IconType } from "../../../components/Icon";
import Placeholder from "../../../components/Placeholder";
import { useHistory } from "react-router-dom";
import { requestModalOpening } from "../../../components/Modal/utils";
import CreateOrganizationModal from "./components/CreateOrganizationModal";
import { createDocument } from "../../../utils/database/databaseRepository";
import { addToQueue } from "../../../components/Toast/utils";

interface IProfileState {
    firstName: string;
    lastName: string;
    email: string;
    errorFirstName?: string;
    errorEmail?: string;
    buttonDisabled: boolean
}

function stateReducer(state: IProfileState, action: Partial<IProfileState>) {
    return { ...state, ...action };
}

const Organizations: () => JSX.Element = () => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const history = useHistory();
    const { setNavBarTitle } = useContext(NavigationContext);
    const [organizations, setOrganizations] = useState<Organization[]>();

    useEffect(() => {
        setNavBarTitle(t('account.profile.organizations.navbar'));
    }, [setNavBarTitle, t])

    useEffect(() => {
        if (user) {
            listenToUserOrganizations(user.id, organizationList => setOrganizations(organizationList));
        }
    }, [user])

    const handleAddOrganization = () => requestModalOpening(CreateOrganizationModal, {
        onValidate: async (name: string, summary: string) => {
            try {
                user && createOrganization(user, name, summary).then(id => history.push(`/o/${id}`)).catch(e => {
                    console.error('error while creating organization', e);
                    addToQueue(t('organization.create.error'));
                });
                return { done: true };
            } catch (error) {
                return { error }
            }
        },
    })

    return (
        <div>
            <SectionTitle>{t('account.profile.organizations.title')}</SectionTitle>
            {organizations ?
                organizations.map(item => (
                    <ListCard key={item.id} imgSource={item.logoPath || ""} text={item.name} subText={"Administrateur"} actionLabel={t('account.profile.organizations.list.manage')} onClick={() => { history.push(`/o/${item.id}`) }} />
                )) :
                (<Placeholder label={t('account.profile.organizations.list.empty')} />)}
            <Button label={t('account.profile.organizations.list.add')} colorStyle={IButtonStyle.positive} iconLeft={IconType.plus} align={IButtonAlign.center} handleClick={() => handleAddOrganization()} />
        </div>
    );
};

export default Organizations;