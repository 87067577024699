// import Home from '../pages/Home';
// import Login from '../pages/Login';
import React from 'react';
import Navbar from "../../components/NavBar";
import Style from 'styled-components';

import Signin from '../../pages/Signin';
import Signup from '../../pages/Signup';
import Home from '../../pages/Home';
import Account from '../../pages/Account';

import { useTranslation } from 'react-i18next';
import BoxPage from "../../pages/Box";
import IdeaPage from '../../pages/Idea';
import OrganizationPage from '../../pages/Organization';

export type Route<T extends string = string> = {
    key: T,
    path: string,
    title?: string,
    exact?: boolean,
    Component: React.FC,
}

export default {
    auth: [
        {
            key: 'home',
            path: '/',
            // title: 'Home',
            Component: () => {
                const { t } = useTranslation();
                return <AppContainer><Navbar /><Home /></AppContainer>
            },
        },
        {
            key: 'login',
            path: '/login',
            // title: 'Home',
            Component: () => <Signin />,
        },
        {
            key: 'account',
            path: '/user',
            // title: 'Home',
            Component: () => {
                const { t } = useTranslation();
                return <AppContainer><Navbar /><Account /></AppContainer>
            },
            exact: false,
        },
        {
            key: 'box',
            path: '/b/:id',
            Component: () => {
                const { t } = useTranslation();
                return <AppContainer><Navbar /><BoxPage /></AppContainer>
            }
        },
        {
            key: 'idea',
            path: '/i/:id',
            Component: () => {
                const { t } = useTranslation();
                return <AppContainer><Navbar /><IdeaPage /></AppContainer>
            }
        },
        {
            key: 'idea',
            path: '/o/:id',
            exact: false,
            Component: () => {
                const { t } = useTranslation();
                return <AppContainer><Navbar /><OrganizationPage /></AppContainer>
            }
        }
    ],
    visitor: [
        {
            key: 'home',
            path: '/',
            title: 'Home',
            Component: () => {
                const { t } = useTranslation();
                return <AppContainer><Navbar /><Home /></AppContainer>
            },
        },
        {
            key: 'login',
            path: '/login',
            // title: 'Home',
            Component: () => <Signin />,
        },
        {
            key: 'signup',
            path: '/signup',
            // title: 'Home',
            Component: () => <Signup />,
        },
    ],
};

const AppContainer = Style.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;