import firebase from 'firebase/app';

import { DatabaseQuery } from '../types/type';
import { databaseOperator } from './databaseUtils';

export const getDocument = async (collection: string, document: string) => {
    const docs = await firebase.firestore().collection(collection).doc(document).get();
    console.log(`document ${collection}/${document} fetched`, docs.data());
    return docs.data();
}

export const listDocuments = async (collection: string) => {
    const docs = await firebase.firestore().collection(collection).get();
    const res: Array<any> = [];
    docs.docs.forEach(doc => res.push(doc.data()));
    return res;
}

export const listDocumentsQuery = async (collection: string, query: DatabaseQuery) => {
    const docs = await firebase.firestore()
        .collection(collection)
        .where(query.leftOperand, databaseOperator(query.operator), query.rightOperand)
        .get();
    const res: Array<any> = [];
    docs.docs.forEach(doc => res.push(doc.data()));
    return res;
}

export const listenToCollection = (collection: string, onCollectionUpdate: (documents: Array<object>) => void) =>
    firebase.firestore().collection(collection).onSnapshot(documents => {
        const res: Array<Object> = [];
        documents.docs.forEach(doc => {
            res.push(doc.data());
        });
        onCollectionUpdate(res);
    });

export const listenToCollectionQuery = (collection: string, query: DatabaseQuery, onCollectionUpdate: (documents: Array<firebase.firestore.DocumentData>) => void) =>
    firebase.firestore()
        .collection(collection)
        .where(query.leftOperand, databaseOperator(query.operator), query.rightOperand)
        .onSnapshot(documents => {
            const res: Array<Object> = [];
            documents.docs.forEach(doc => {
                const data = doc.data();
                data.id = doc.id;
                res.push(data);
            });
            onCollectionUpdate(res);
        });

export const listenToDocument = (collectionPath: string, documentId: string, onDocumentUpdate: (data: firebase.firestore.DocumentData | undefined) => void) => 
        firebase.firestore().doc(`/${collectionPath}/${documentId}`)
        .onSnapshot({ next: (doc) => {
            onDocumentUpdate(doc.data());
        }});

export const createDocument = async (collection: string, data: Object): Promise<any> =>
    await firebase.firestore().collection(collection).add(data);

export const createDocumentWithId = async (collection: string, document: string, data: Object): Promise<void> =>
    await firebase.firestore().collection(collection).doc(document).set(data);

export const updateDocument = async (documentPath: string, data: Object): Promise<void> =>
    await firebase.firestore().doc(documentPath).update(data);

export const incrementValueInDocument = async (documentPath: string, key: string): Promise<void> =>
    await firebase.firestore().doc(documentPath).update(key, firebase.firestore.FieldValue.increment(1));

export const decrementValueInDocument = async (documentPath: string, key: string): Promise<void> =>
    await firebase.firestore().doc(documentPath).update(key, firebase.firestore.FieldValue.increment(-1));

export const deleteDocument = async (documentPath: string): Promise<any> =>
    await firebase.firestore().doc(documentPath).delete();