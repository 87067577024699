import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Style from 'styled-components';
import { Modal } from '../../../../components/Modal/type';
import { requestModalClosing } from '../../../../components/Modal/utils';
import TextField from '../../../../components/TextField';
import Button, { IButtonStyle } from '../../../../components/Button';

const CreateOrganizationModal: Modal = ({clickedOutside, additionalProps: { onValidate }}) => {
  const { t } = useTranslation();
  const [value, updateValue] = useState<string>('');
  const [summary, updateSummary] = useState<string>('');
  const [loading, updateLoading] = useState<boolean>(false);
  const [error, updateError] = useState<string | null>(null);
  if (clickedOutside && !loading)
    requestModalClosing();
  return (
    <Container>
      <Title>{t('organization.create.title')}</Title>
      <Desc>{t('organization.create.desc')}</Desc>
      <Warning>{t('organization.create.warning')}</Warning>
      <TextField 
        hint={t('organization.create.field')} 
        onChange={(e) => !loading && updateValue(e.target.value) && updateError(null)} 
        value={value}
        error={error}
        marginBottom={12} 
      ></TextField>
      <TextField 
        hint={t('idea.create.textfield.content.hint')} 
        placeholder={t('idea.create.textfield.content.placeholder')} 
        onChange={(e) => !loading && updateSummary(e.target.value)} 
        value={summary} 
        marginBottom={36} 
        maxLength={3000} 
        maxLines={10} 
      />
      <Buttons>
        <Button 
          label={t('organization.create.cancel')} 
          handleClick={requestModalClosing}
          loading={loading}
        ></Button>
        <Button 
          label={t('organization.create.validate')} 
          colorStyle={IButtonStyle.positive} handleClick={() => {
            updateLoading(true);
            onValidate(value, summary).then((res: { done?:boolean, error?:string }) => {
              if (res.done)
                requestModalClosing();
              else {
                updateLoading(false);
                updateError(error);
              }
            });
          }} 
          loading={loading}
        ></Button>
      </Buttons>
    </Container>
  );
}

const Container = Style.div`
  padding: ${({ theme }) => theme.large.desktop};
  box-shadow: 0px 1px 81px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
`;

const Title = Style.div`
  ${({ theme }) => theme.heading.desktop}
  margin-bottom: ${({ theme }) => theme.large.desktop};
`;
const Desc = Style.div`
  margin-bottom: ${({ theme }) => theme.small.desktop};
`;
const Warning = Style.div`
  color: ${({ theme }) => theme.onVariant};
  margin-bottom: ${({ theme }) => theme.large.desktop};
`;
const Buttons = Style.div`
  display: flex;
  justify-content: space-between;
`;

export default CreateOrganizationModal;