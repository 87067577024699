import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import style from 'styled-components';
import { signOut } from '../../utils/authentication/useAuth';
import Icon from '../IconDeprecated';

export enum IAvatarType {
    normal,
    large
}

interface IUserCircleProps {
    initials: string;
    name?: string;
    type?: IAvatarType;
}

const UserCircle = ({ initials, name, type = IAvatarType.normal }: IUserCircleProps) => {
    const history = useHistory();
    const { t } = useTranslation();

    switch (type) {
        case IAvatarType.large:
            return (
                <LargeAvatar>
                    {initials}
                </LargeAvatar>
            )
        default:
            return (
                <Content>
                    <UserAvatar>
                        {initials}
                        {name && (
                            <Dropdown>
                                <Name>{name}</Name>
                                <Link to={'/user/account'}>
                                    <Button>
                                        <Span><Icon iconCode={"far fa-user-circle"} /></Span>
                                        {t('account.navbar.menu.my_account')}
                                    </Button>
                                </Link>
                                <Link to={'/user/organizations'}>
                                    <Button>
                                        <Span><Icon iconCode={"far fa-user-friends"} /></Span>
                                        {t('account.navbar.menu.my_organizations')}
                                    </Button>
                                </Link>
                                <ButtonLogOut onClick={() => signOut(history)}>
                                    <Span><Icon iconCode={"far fa-sign-out-alt"} /></Span>
                                    {t('account.navbar.menu.logout')}
                                </ButtonLogOut>
                            </Dropdown>
                        )}
                    </UserAvatar>
                </Content>
            );
    }
};

const Button = style.a`
    color: ${props => props.theme.onBackground} !important;
    padding: 12px 24px;
    display: flex;
    ${props => props.theme.transition}
    &:hover {
        background-color: ${props => props.theme.primaryLight};
        color: ${props => props.theme.primary} !important;
    }
`;

const ButtonLogOut = style.a`
    color: ${props => props.theme.error} !important;
    padding: 12px 24px;
    display: flex;
    padding-bottom: 18px;
    ${props => props.theme.transition}
    &:hover {
        background-color: ${props => props.theme.errorLight};
    }
`;

const Span = style.span`
    margin-right: 10px;
    width: 32px;
    text-align: center;
`;

const Content = style.div`
    position: relative;
    display: inline-block;
`;

const UserAvatar = style.div`
    width: 42px;
    height: 42px;
    background-color: ${props => props.theme.backgroundMedium};
    border-radius: 50%;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.theme.transition}
    &:hover {
        background-color: ${props => props.theme.backgroundBold};
        ${props => props.theme.transition}
    }
`;

const LargeAvatar = style.div`
    width: 66px;
    height: 66px;
    background-color: ${props => props.theme.backgroundMedium};
    border-radius: 50%;
    font-weight: bold;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.theme.transition}
    &:hover {
        background-color: ${props => props.theme.backgroundBold};
        ${props => props.theme.transition}
    }
`;

const Dropdown = style.div`
    display:flex;
    visibility: hidden;
    flex-direction: column;
    width: 300px;
    top: 0px;
    text-align: left;
    right: -15px;
    opacity: 0;
    font-weight: 600;
    border-radius: 6px;
    ${props => props.theme.action.desktop};
    position: absolute;
    background-color: ${props => props.theme.surface};
    min-width: 160px;
    box-shadow: 0px 0px 42px 0px rgba(0,0,0,0.3);
    z-index: 1;
    ${props => props.theme.transition}
    ${UserAvatar}:hover & {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s, opacity 0.1s linear;
    }
`;

const Name = style.p`
    margin-left: 16px;
    margin-top: 16px;
    font-weight: 800;
    color: ${props => props.theme.onSurface}
`;

export default UserCircle;