import React, {useContext, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Style from './style';
import LogoBrusketa from '../../assets/logo_large_white.svg';
import Input from '../../components/TextField';
import { signUp } from '../../utils/authentication/useAuth';
import { useTranslation } from 'react-i18next';
import Button, { IButtonStyle, IMargin, IWidth } from '../../components/Button';
import {UserContext} from "../../utils/contexts/UserContext";

const Signin: React.FC = (): JSX.Element => {
    const {reloadUserInfo} = useContext(UserContext);
    const history = useHistory();
    const { t } = useTranslation();

    // Loading State
    const [isLoading, updateIsLoading] = useState(false);
    // Fields
    const [email, updateEmail] = useState("");
    const [password, updatePassword] = useState("");
    const [name, updateName] = useState("");
    const [firstName, updateFirstName] = useState("");
    // Errors
    const [errorMail, updateErrorMail] = useState(null);
    const [errorPassword, updateErrorPassword] = useState(null);
    const [errorName, updateErrorName] = useState<string | null>(null);
    const [errorFirstName, updateErrorFirstName] = useState<string | null>(null);
    const handleSubmit = (event: any) => {
        event.preventDefault();
        // Prevent request if loading
        if (isLoading) return false;
        // Prevent request if field missing
        if (!email || !firstName || !password) {
            if (!firstName) {
                updateErrorFirstName(t('error.required'));
            }
            if (!email) {
                updateErrorMail(t('error.required'));
            }
            if (!password) {
                updateErrorPassword(t('error.required'));
            }
        // Make request
        } else {
            updateIsLoading(true);
            signUp(email, password, firstName, name, () => {
                reloadUserInfo();
                history.push('/');
            }).catch(error => {
                switch (error.code) {
                    case 'auth/invalid-email': updateErrorMail(t(error.code));
                        break;
                    case 'auth/email-already-in-use': updateErrorMail(t(error.code));
                        break;
                    case 'auth/weak-password': updateErrorPassword(t(error.code));
                        break;
                    case 'auth/network-request-failed': updateErrorPassword(t(error.code));
                        break;
                    default:
                        console.error(error.code);
                        updateErrorMail(error.message);
                        updateErrorPassword(error.message);
                }
            })
            .finally(() => {
                updateIsLoading(false);
            });
        }
        return false;
    };
    const resetErrors = () => {
        updateErrorMail(null);
        updateErrorPassword(null);
    };
    return (
        <Style.Container>
            <Style.LeftDiv>
                <Link to="/"><Style.Logo src={LogoBrusketa} alt={"Brusketa logo"} /></Link>
                <div>
                    <div>{t('signin.title')}</div>
                    <div>{t('signin.catchphrase')}</div>
                </div>
            </Style.LeftDiv>
            <Style.RightDiv>
                <Style.Form onSubmit={handleSubmit} autoComplete='on'>
                    <Style.Title>{t('signup.form.title')}</Style.Title>
                    <Style.Desc>{t('signup.form.description')}</Style.Desc>
                    <Style.Desc><Link to="/login">{t('signup.form.signin.link')}</Link></Style.Desc>
                    <Input hint={t('general.hint.firstname_or_pseudo')} onChange={event => { updateFirstName(event.target.value); updateErrorFirstName(null) }} value={firstName} error={errorFirstName} autocomplete='given-name' autofocus={true} />
                    <Input hint={t('general.hint.lastname_facultative')} onChange={event => { updateName(event.target.value); updateErrorName(null) }} value={name} error={errorName} />
                    <Input hint={t('general.hint.email')} onChange={event => { updateEmail(event.target.value); resetErrors(); }} value={email} error={errorMail} />
                    <Input hint={t('general.hint.password')} onChange={event => { updatePassword(event.target.value); resetErrors(); }} value={password} type="password" error={errorPassword} />
                    <Button loading={isLoading} label={t('signup.form.create')} width={IWidth["match-parent"]} colorStyle={IButtonStyle.dark} margin={IMargin.medium} />
                    <Style.Legal>{t('signup.form.terms')}<Link to="/legal">{t('signup.form.terms.link')}</Link></Style.Legal>
                </Style.Form>
            </Style.RightDiv>
        </Style.Container>
    );
};

export default Signin;