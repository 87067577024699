import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import ToastContainer from './container';

const ToastRef = () => {
    useEffect(() => {
        ReactDOM.render(
            <ToastContainer />,
            document.getElementById('toast')
        );
    }, []);
    return null;
}

export default ToastRef;