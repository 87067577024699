export interface Toast {
  text: string,
  id: string,
  style?: ToastStyle
}

export interface ToastUnitProps {
  text:string,
  style: Object,
  toastStyle?: ToastStyle
}

export enum TransitionStyle {
  entering = 'entering',
  entered = 'entered',
  exiting = 'exiting',
  exited = 'exited',
}

export enum ToastStyle {
  Normal,
  Error,
  Success
}