import React, { createContext, useEffect, useState } from "react";
import firebase from 'firebase/app';
import { getUser } from "../repositories/userRepository";
import { User } from "../types/type";

export const UserContext = createContext<{ user: User | null, reloadUserInfo: () => void | null }>({
    user: null,
    reloadUserInfo: () => null
});

const UserContextProvider: React.FC = ({ children }): JSX.Element => {
    const [user, setUser] = useState<User | null>(null);

    const retrieveCurrentUserInfo = () => {
        const userAuth: firebase.User | null = firebase.auth().currentUser;
        if (userAuth) {
            getUser(userAuth.uid).then(res => setUser(res)).catch(e => setUser(null))
        } else {
            setUser(null);
        }
    };

    useEffect(() => firebase.auth().onAuthStateChanged(() => retrieveCurrentUserInfo()), [])

    return (
        <UserContext.Provider value={{ user, reloadUserInfo: retrieveCurrentUserInfo }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
