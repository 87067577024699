import { QueryOperator } from '../types/type';

export const databaseOperator = (operator: QueryOperator): firebase.firestore.WhereFilterOp => {
    switch (operator) {
        case "array-contains":
            return 'array-contains';
        case "equals":
            return '==';
        case "less-than":
            return '<';
        case "less-than-or-equal-to":
            return '<=';
        case "array-contains-any":
            return 'array-contains-any';
        case "in":
            return 'in';
        case "not-in":
            return 'not-in';
        case "greater-than":
            return '>';
        case "greater-than-or-equal-to":
            return '>=';
        default:
            return "==";
    }
}