import { firestore } from "firebase";
import { Organization, OrganizationAdministrator } from "../types/type";

export const mapDataToOrganization = (obj: any): Organization => {

    const admins: OrganizationAdministrator[] = obj.administrators.map((a: unknown) => a as OrganizationAdministrator)

    if (obj) {
        return obj && {
            id: obj.id,
            name: obj.name,
            contactEmail: obj.contactEmail,
            contactPhone: obj.contactPhone,
            contactWebsite: obj.contactWebsite,
            description: obj.description,
            summary: obj.summary,
            logoPath: obj.logoPath,
            coverPath: obj.coverPath,
            creationDate: new Date((obj.creationDate as firestore.Timestamp).valueOf()),
            lastUpdateDate: new Date((obj.lastUpdateDate as firestore.Timestamp).valueOf()),
            status: obj.status,
            administrators: admins
        }
    } else {
        throw new Error("impossible to map data to Organization: " + obj);
    }
}