import React, { useContext, useEffect } from 'react';
import MainObject from '../../components/MainObject';
import style from 'styled-components';
import { useTranslation } from 'react-i18next';
import SectionTitle from '../../components/SectionTitle';

import InformationCard from '../../components/Card/Information';
import TextField from '../../components/TextField';
import BoxGrid from '../../components/BoxGrid';
import SearchBoxGrid from '../../components/BoxGrid/SearchBoxGrid';

import { listAllBoxes } from '../../utils/repositories/boxRepository';
import { Box as BoxType } from '../../utils/types/type';
import { IconType } from '../../components/Icon';
import { NavigationContext } from '../../utils/contexts/NavigationContext';

const Home: React.FC = (): JSX.Element => {

    const { setNavBarTitle } = useContext(NavigationContext);

    const { t } = useTranslation();
    const [searchString, updateSearchString] = React.useState('');

    // Fetch of all boxes
    const [allBoxes, updateAllBoxes] = React.useState<BoxType[]>([]);

    useEffect(() => {
        listAllBoxes().then(b => updateAllBoxes(b));
        setNavBarTitle(t('home.navbar.title'));
    }, []);

    return (
        <MainObject>
            <CenterContainer>
                <SectionTitle>{t('home.information.title')}</SectionTitle>
                <InformationCard title="Première fois sur Brusketa ?">Ravis de vous rencontrer ! Et si on vous montrait
                    comment ça marche en 1 minute top chrono ? 🎬</InformationCard>
                <SectionTitle>{t('home.search.title')}</SectionTitle>
                <TextField placeholder={t('home.search.placeholder')}
                    onChange={event => updateSearchString(event.target.value)} value={searchString} error=''
                    icon={IconType.search} autofocus={true} />
                <SearchBoxGrid boxes={allBoxes} searchString={searchString} />
                <SectionTitle>{t('home.trends.title')}</SectionTitle>
                <BoxGrid boxes={allBoxes} />
            </CenterContainer>
        </MainObject>
    );
}

export default Home;

const CenterContainer = style.div`
`