import React, { useContext, useEffect, useState } from 'react';
import style from 'styled-components';
import MainObject from "../../components/MainObject";
import BoxIdeasList from "../../components/BoxIdeasList";
import { loadIdeasFromBox, useFilteredAndSortedIdeas, useListenerToBox } from "../../utils/firebase/useFilteredIdeas";
import { useParams } from "react-router";
import Desc from './Desc';
import { Box } from './../../utils/types/type';
import { getBox } from '../../utils/repositories/boxRepository';
import Breadcrumb from '../../components/Breadcrumb/BreadcrumbIdea';
import { NavigationContext } from '../../utils/contexts/NavigationContext';
import Placeholder from '../../components/Placeholder';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../utils/contexts/UserContext';

interface Params {
    id: string,
}

const BoxPage: () => JSX.Element = () => {
    const { id } = useParams<{ id: string }>(); // box id in the url
    const { user } = useContext(UserContext);
    const [box, setBox] = useState<Box>();
    const { t } = useTranslation();

    const { setNavBarTitle } = useContext(NavigationContext);

    const { ideas, error } = useFilteredAndSortedIdeas();

    useEffect(() => {
        if (user && id.length > 0) {
            loadIdeasFromBox(id, user.id);
            getBox(id).then(b => {
                setNavBarTitle(b.name);
                setBox(b)
            })
        }
    }, [id, setNavBarTitle, user]);

    // const [box, updateBox] = React.useState<BoxType>();
    const orgaName = (!!box) ? box.organization?.name : "";
    const orgaId = (!!box) ? box.organization?.id : "";
    const boxName = (!!box) ? box.name : "";
    const loading = !box;

    return (
        <MainObject>
            <LeftContainer>
            </LeftContainer>
            <CenterContainer>
                <Breadcrumb loading={box === undefined} organisationLabel={orgaName || ""} organisationLink={`../o/${orgaId}`} boxLabel={boxName} />
                <Divider />
                {ideas.length > 0 ?
                    (<BoxIdeasList ideas={ideas} user={user || undefined} />) :
                    (<Placeholder label={t('box.list.placeholder')} />)
                }
                <Divider />
            </CenterContainer>
            <RightContainer>
                <Divider />
                <Desc box={box} user={user || undefined} organizationName={orgaName} organizationId={orgaId} />
            </RightContainer>
        </MainObject>
    );
};

const LeftContainer = style.div`
`;

const CenterContainer = style.div`
    width: 692px;
    padding-bottom: 36px;
`;

const RightContainer = style.div`
    margin-top: -8px !important;
`;

const Divider = style.div`
    height: 24px;
`;

export default BoxPage;