import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Style from 'styled-components';
import { UserContext } from '../../utils/contexts/UserContext';
import { updateDocument } from '../../utils/database/databaseRepository';
import { Idea, IdeaComment } from '../../utils/types/type';
import Avatar from '../Avatar';
import Button from '../Button';
import TextField from '../TextField';

const CommentSection: (props: { idea: Idea }) => JSX.Element | null = ({ idea }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [newComment, updateNewComment] = useState<string>('');
  return (
    <div>
      <RedactionContainer>
        <TextField onChange={(e) => updateNewComment(e.target.value)} value={newComment}></TextField>
        <Button 
          label={t('idea.detail.comment.validate')}
          handleClick={() => {
            if (!!newComment && user) {
              const comment: IdeaComment = {
                content: newComment,
                createdAt: new Date(),
                createdBy: {
                  id: user.id,
                  name: `${user.firstName}${user.lastName ? ` ${user.lastName}` : ''}`,
                }
              };
              updateDocument(`/ideas/${idea.id}`, {
                comments: idea.comments ? [...idea.comments, comment] : [comment],
              }).then(() => {
                updateNewComment('');
              });
            }
          }}
        ></Button>
      </RedactionContainer>
      <div>
        {idea.comments?.map(i => (
          <CommentContainer key={i.createdAt.toString()}>
            <AvatarContainer><Avatar initials={i.createdBy.name.substring(0, 2).toUpperCase()}></Avatar> </AvatarContainer>
            <div>
              <TitleContainer>
                <div>{i.createdBy.name}</div>
                <div>{moment(i.createdAt).format('DD MMMM')}</div>
              </TitleContainer>
              <ContentContainer>
                {i.content}
              </ContentContainer>
            </div>
          </CommentContainer>
        ))}
      </div>
    </div>
  );
}

const RedactionContainer = Style.div`
  background: ${({theme}) => theme.surface};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({theme}) => theme.regular.desktop};
  justify-content: space-between;
  margin-bottom: ${({theme}) => theme.regular.desktop};
  & > div:first-child {
    width: 100%;
    margin-right: 14px;
  }
`;

const CommentContainer = Style.div`
  background: ${({theme}) => theme.surface};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({theme}) => theme.regular.desktop};
  padding-left: 0;
  margin-bottom: ${({theme}) => theme.regular.desktop};
`;

const TitleContainer = Style.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({theme}) => theme.small.desktop};
  & > div:nth-child(1) {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.3px;

    color: #000000;
  }
  & > div:nth-child(2) {
    margin-left: ${({theme}) => theme.regular.desktop};
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.47px;

    display: flex;
    justify-content: center;
    align-items: center;

    /* On Variant */

    color: ${({theme}) => theme.onVariant};
  }
`;

const ContentContainer = Style.div`
  ${({theme}) => theme.information.desktop}
  color: ${({theme}) => theme.onBackground};
  overflow-wrap: anywhere;
`;

const AvatarContainer = Style.div`
  padding: 0 22px;
`;

export default CommentSection;