import React from 'react';
import ReactMarkdown from 'react-markdown';
import Style from 'styled-components';
import Button from '../../../../components/Button';
import { IconType } from '../../../../components/Icon';
import { Organization } from '../../../../utils/types/type';

const OrganizationCard: (props: { organization: Organization }) => JSX.Element = ({ organization }) => {
    return (
        <Container image={organization.coverPath}>
            <Title>{organization.name}</Title>
            <Desc><ReactMarkdown>{organization.summary || ''}</ReactMarkdown></Desc>
            <Button label="Plus d'informations" iconLeft={IconType['arrow-right']} />
        </Container>
    );
}

const Container = Style.div<{ image: string | null }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: ${({ theme }) => theme.medium.desktop} ${({ theme }) => theme.large.desktop};
  position: relative;
  background: linear-gradient(272.54deg, rgba(0, 0, 0, 0.29) 5.34%, rgba(0, 0, 0, 0.01) 43.55%, rgba(0, 0, 0, 0) 60.19%); 
  &::before {
    content: "";
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%; 
    height: 100%;  
    z-index: -1;
    background: url(${({ image }) => image});
  }
  & > div {
    position: relative;
  }
`;

const Title = Style.div`
  ${({ theme }) => theme.heading.desktop};
  color: ${({ theme }) => theme.surface};
  margin-bottom: ${({ theme }) => theme.regular.desktop};
`;

const Desc = Style.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.surface};
  margin-bottom: ${({ theme }) => theme.medium.desktop};
  max-width: 50%;
`;

export default OrganizationCard;