import React, { createContext, useState } from "react";

export const NavigationContext = createContext<{ title: string, setNavBarTitle: (t: string) => void }>(
    {
        title: "Brusketa",
        setNavBarTitle: (t: string) => null
    }
);

const NavigationContextProvider: React.FC = ({ children }): JSX.Element => {
    const [title, setTitle] = useState('');

    const setNavBarTitle = (t: string) => setTitle(t);

    return (
        <NavigationContext.Provider value={{ title, setNavBarTitle }}>
            {children}
        </NavigationContext.Provider>
    );
};

export default NavigationContextProvider;
