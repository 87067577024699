import React from 'react';
import { ThemeProvider as ThemeProviderBase } from 'styled-components';

const lightTheme = {
    // colors
    primary: '#0079FF',
    primaryLight: '#BFDDFF',
    onSurface: '#1F1F1F',
    onSurfacePure: '#000000',
    onVariant: '#B0B0B0',
    background: '#F4F4F4',
    backgroundLight: '#FCFCFC',
    backgroundMedium: '#EAEAEA',
    backgroundBold: '#D9D9D9',
    error: '#EA4335',
    errorLight: '#FAD0CC',
    secondary: '#FBBC05',
    secondaryLight: '#FEEEC0',
    success: '#27AE60',
    successLight: '#C9EBD7',
    surface: '#FFFFFF',
    onBackground: '#616161',
    // fonts
    heading: {
        desktop: 'font-family: inherit; font-size:22px; font-weight: 700;',
        mobile: 'font-family: inherit; font-size:18px; font-weight: 600; letter-spacing: 0.2px',
    },
    paragraphTitle: {
        desktop: 'font-family: inherit; font-size:19px; font-weight: 600; letter-spacing: 0.2px',
        mobile: 'font-family: inherit; font-size:18px; font-weight: 600; letter-spacing: 0.2px',
    },
    information: {
        desktop: 'font-family: inherit; font-size:15px; font-weight: 500; letter-spacing: 0.2px',
        mobile: 'font-family: inherit; font-size:15px; font-weight: 500; letter-spacing: 0.2px',
    },
    body: {
        desktop: 'font-family: inherit; font-size:17px; font-weight: 500; letter-spacing: 0.2px; line-height: 28px;',
        mobile: 'font-family: inherit; font-size:15px; font-weight: 500; letter-spacing: 0.2px',
    },
    action: {
        desktop: 'font-family: inherit; font-size:18px; font-weight: 600;',
        mobile: 'font-family: inherit; font-size:16px; font-weight: 600; letter-spacing: 0.2px',
    },
    extraLarge: {
        desktop: 'font-family: inherit; font-size:42px; font-weight: 700; letter-spacing: 0.8px',
        mobile: 'font-family: inherit; font-size:32px; font-weight: 700; letter-spacing: 0.8px',
    },
    // utils
    noSelect: ' -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none;-ms-user-select: none; user-select: none; cursor: default;',
    disabled: 'cursor: not-allowed;',
    noOutline: 'outline: none;',
    // spacings
    micro: {
        desktop: '2px',
        mobile: '2px'
    },
    small: {
        desktop: '8px',
        mobile: '8px'
    },
    regular: {
        desktop: '12px',
        mobile: '10px'
    },
    medium: {
        desktop: '24px',
        mobile: '16px',
    },
    large: {
        desktop: '36px',
        mobile: '20px',
    },
    huge: {
        desktop: '48px',
        mobile: '28px',
    },
    extreme: {
        desktop: '60px',
        mobile: '48px',
    },
    // transitions
    transition: 'transition: 0.1s;'
};

const ThemeProvider: React.FC = ({ children }): JSX.Element => {
    return (
        <ThemeProviderBase theme={lightTheme}>
            {children}
        </ThemeProviderBase>
    );
};

export default ThemeProvider;