import React from 'react';
import Style from 'styled-components';

const Container : React.FC = ({ children }) : JSX.Element => {
  return (
    <ContainerBase>
      {children}
    </ContainerBase>
  );
};

export default Container;

const ContainerBase = Style.div`
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
`;