import React from 'react';
import style from 'styled-components';

/**
 * 
 * @example
 * 
 *  <MainObject>
      <Left>Hello world</Left>
      <Main>Test</Main>
      <ToolBox>Hello world</ToolBox>
    </MainObject>
 */

const MainObject: React.FC = ({ children }): JSX.Element => {
    switch (React.Children.count(children)) {
        case 1: {
            return <ContainerOne>{children}</ContainerOne>
        }
        case 2: {
            return <ContainerTwo><Pad></Pad>{children}</ContainerTwo>
        }
        default: {
            return <ContainerThree>{children}</ContainerThree>
        }
    }
}

export default MainObject;

const ContainerOne = style.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    & > div:nth-child(1) {
        margin-left: auto;
        margin-right: auto;
        max-width: 900px;
        min-width: 500px;
    }
`;

const ContainerTwo = style.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    & > div {
        flex-grow: 1;
        margin: 12px;
    }
    & > div:nth-child(1) {
    width: 20%;
    transition: width 0.2s;
    }
    & > div:nth-child(2) {
        width: 70%;
        flex-grow: 2;
        max-width: 900px;
        min-width: 500px;
        transition: width 0.2s;
    }
    & > div:nth-child(3) {
        margin-left: 24px;
        margin-right: auto;
        flex-grow: 1;
        width: 20%;
        position: sticky;
        top: 0;
        height: fit-content;
    }
    @media (max-width: 1600px) {
        display: flex;
        & > div:nth-child(1) {
            width: 0%
        }
        & > div:nth-child(2) {
            margin-left: auto;
        }
        & > div:nth-child(3) {
            margin-left: 24px;
        }
    }
    @media (max-width: 1320px) {
        display: flex;
        & > div:nth-child(1) {
            width: 0%
        }
        & > div:nth-child(2) {
            margin-left: 24px;
            max-width: 800px;
        }
    }
    @media (max-width: 100px) {
        flex-direction: column;
        & > div:nth-child(1) {
        }
        & > div:nth-child(2) {
            margin-left: 24px;
            max-width: 800px;
        }
    }
`;

const Pad = style.div`
`;

const ContainerThree = style.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 12px;
  box-sizing: border-box;
  background-color: ${props => props.theme.background};
  justify-content: space-around;
  display: flex;
  & > div {
    flex-grow: 1;
    margin: 12px;
  }
  & > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  & > div:nth-child(2) {
    flex-grow: 2;
    max-width: 50%;
  }
  & > div:nth-child(3) {
    position: sticky;
    top: 0;
    height: fit-content;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 8px 0;
    & > div {
      margin: 8px 16px;
    }
    & > div:nth-child(2) {
      max-width: none;
    }
  }
`;