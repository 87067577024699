import { firestore } from "firebase";
import { Idea, IdeaAuthor, IdeaBox, IdeaComment, IdeaOrganization, IdeaStatistics, IdeaStatus, IdeaTag } from "../types/type";

export const mapDataToIdea = (obj: any): Idea => {
    if (obj) {
        const author: IdeaAuthor = {
            firstName: obj.author.firstName,
            lastName: obj.author.lastName,
            id: obj.author.id
        }

        const box: IdeaBox = {
            id: obj.box.id,
            title: obj.box.title
        }

        const organization: IdeaOrganization = {
            id: obj.organization.id,
            name: obj.organization.name
        }

        const statistics: IdeaStatistics = {
            nbOfComments: obj.statistics.nbOfComments,
            nbOfLikes: obj.statistics.nbOfLikes,
            nbOfView: obj.statistics.nbOfViews
        }

        const ideaStatus: IdeaStatus | undefined = obj.ideaStatus ? {
            id: obj.ideaStatus.id,
            description: obj.ideaStatus.description,
            label: obj.ideaStatus.label,
        } : undefined

        const comments: IdeaComment[] | undefined = obj.comments ? 
            obj.comments.map((o: Object & { createdAt: { toDate: Function } }) => ({ ...o, createdAt: o.createdAt.toDate() }) as IdeaComment)
        : undefined;

        return obj && {
            id: obj.id,
            organization: organization,
            box: box,
            title: obj.title,
            body: obj.body,
            author: author,
            ideaStatus: ideaStatus,
            status: obj.status,
            statistics: statistics,
            creationDate: new Date((obj.creationDate as firestore.Timestamp).valueOf()),
            lastUpdateDate: new Date((obj.lastUpdateDate as firestore.Timestamp).valueOf()),
            comments,
        }
    } else {
        throw new Error("impossible to map data to Organization: " + obj);
    }
}