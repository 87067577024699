import React, { useContext, useEffect, useState } from "react";
import LogoBrusketa from '../../assets/logo_large.svg';
import NavbarActions from "./NavbarActions";
import Style from 'styled-components';
import { UserContext } from "../../utils/contexts/UserContext";
import { Link } from "react-router-dom";
import { NavigationContext } from "../../utils/contexts/NavigationContext";
import { getUserInitials, getUserName } from "../../utils/repositories/userRepository";

// TODO : see how to retrieve user organisation and add them to props

const Navbar = () => {
    const { user } = useContext(UserContext);
    const { title } = useContext(NavigationContext);
    let [initials, setInitials] = useState("");
    let [name, setName] = useState("");

    useEffect(() => {
        setInitials(getUserInitials(user));
        setName(getUserName(user));
    }, [user])

    return (
        <NavBarContainer>
            <FlexContainer>
                <Left>
                    <Link to='/'><MainLogo src={LogoBrusketa} alt={"Brusketa logo"} /></Link>
                </Left>
                <Center>
                    <NavBarTitle>{title}</NavBarTitle>
                </Center>
                <Right>
                    <NavbarActions isAuth={user != null} hasOrganisation={false} initials={initials} name={name} />
                </Right>
            </FlexContainer>
        </NavBarContainer>
    );
};

export default Navbar;

const NavBarContainer = Style.div`
    display: grid;
    height: 60px;
    z-index: 1;
    background-color: ${props => props.theme.surface};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const FlexContainer = Style.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
`;

const Left = Style.div`
    width: 0;
    margin-left: 22px;
`;

const Center = Style.div`
`;

const Right = Style.div`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 22px;
    width: 0;
`;

const MainLogo = Style.img`
    height: 25px;
    margin: 15px 0 10px 0;
`;

const NavBarTitle = Style.div`
    color: ${props => props.theme.primary};
    ${props => props.theme.heading.desktop};
    font-weight: 600;
`;