import React from 'react';
import Container from "./Container";
import style from "styled-components";
import { Idea } from '../../utils/types/type';
import ReactMarkdown from 'react-markdown';

interface IdeaProps {
    idea: Idea;
}

export const IdeaContentCard: React.FC<IdeaProps> = ({ idea }): JSX.Element => {
    return (
        <Container>
            <ContentWrapper>
                <Title>{idea.title}</Title>
                <Description><ReactMarkdown>{idea.body}</ReactMarkdown></Description>
            </ContentWrapper >
        </Container >
    );
};

const ContentWrapper = style.div`
    padding: 42px 100px;
    cursor: pointer;
    margin-bottom: 12px;
    background-color: ${props => props.theme.surface};
    border-radius: 8px;
    transition: box-shadow 0.2s;
    &:hover {
        box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.10);
    }
`;

const Title = style.h1`
    ${props => props.theme.paragraphTitle.desktop};
    color: ${p => p.theme.onBackground};
    margin: 0 0 24px 0;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const Description = style.h3`
    ${props => props.theme.body.desktop};
    margin: 0 0 16px 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: ${p => p.theme.onBackground};
`;