import React from 'react';
import {
    Route,
} from "react-router-dom";

import { Route as RouteType } from './routes';

const generateRoutes: (config: RouteType[]) => JSX.Element[] = (config) => {
  return config.map((r) => <Route path={r.path} key={r.key} exact={r.exact === null || r.exact === undefined || r.exact}><r.Component {...r} /></Route>)
}

export default generateRoutes;
