import React from 'react';
import { Idea, User } from '../../utils/types/type';
import { ListIdeaItem } from "../Card/ListIdeaItem";

interface IBoxIdeasListProps {
    ideas: Idea[];
    user?: User;
}

const BoxIdeasList: React.FC<IBoxIdeasListProps> = ({ ideas, user }): JSX.Element => {

    return (
        <div>{ideas.map((idea) => <ListIdeaItem idea={idea} user={user} />)}</div>
    );
};

export default BoxIdeasList;