import * as React from 'react';
import style from 'styled-components';

interface IToolTipProps {
    label: string;
    color?: string;
}

const ToolTip = ({ label, color = "" }: IToolTipProps) => {
    if (color.length === 0) {

    }
    return (
        <ToolTipContainer>
            <ToolTipLabel>{label}</ToolTipLabel>
        </ToolTipContainer>
    );
};

const ToolTipContainer = style.div`
     position: relative;
  display: none;
  border-bottom: 1px dotted black;

  &::after {
 content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  }
`;

const ToolTipLabel = style.p`
    visibility: visible;
  bottom: 100%;
  left: 50%; 
  margin-left: -80px;
  bottom: 100%;
width: max-content;
min-width: 100px;
max-width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 12px;
  opacity: 1;
  transition: opacity 1s;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
`;

export default ToolTip;