import React from 'react';
import './Overlay.css';
import './animation.css';
import logoLarge from './../../assets/logo_large.svg';


const Loading = () => {
    return (
        <div className="overlay">
            <div className="overlay__inner fade-in-fwd">
                <img className="overlay__logo" src={logoLarge} alt="Brusketa"/>
                <div className="overlay__content"><span className="spinner"/></div>
            </div>
        </div>
    );
}

export default Loading;

const LoadingContainer = () => {
    return (
            <div className="container overlay__container">
                <div className="overlay__content"><span className="spinner"/></div>
            </div>

    );
}
 
export {Loading, LoadingContainer};