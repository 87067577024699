import React, { useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import style from 'styled-components';
import SectionTitle from "../../../components/SectionTitle";
import { UserContext } from "../../../utils/contexts/UserContext";
import firebase from 'firebase/app';
import TextField from "../../../components/TextField";
import Button, { IButtonStyle } from "../../../components/Button";
import { addToQueue } from "../../../components/Toast/utils";
import Delete from './components/Delete';
import { signOut, updateUserEmail, updateUserName } from "../../../utils/authentication/useAuth";
import { useHistory } from "react-router";
import { updateUser } from "../../../utils/repositories/userRepository";
import { NavigationContext } from "../../../utils/contexts/NavigationContext";

interface IProfileState {
    firstName: string;
    lastName: string;
    email: string;
    errorFirstName?: string;
    errorEmail?: string;
    buttonDisabled: boolean
}

function stateReducer(state: IProfileState, action: Partial<IProfileState>) {
    return { ...state, ...action };
}

const Profile: () => JSX.Element = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const userAuth: firebase.User | null = firebase.auth().currentUser;
    const { user, reloadUserInfo } = useContext(UserContext);
    const { setNavBarTitle } = useContext(NavigationContext);

    const initUserInfo = {
        firstName: user?.firstName ? user.firstName : "",
        lastName: user?.lastName ? user.lastName : "",
        email: user?.email ? user.email : "",
    };

    const initState = {
        ...initUserInfo,
        errorFirstName: "",
        errorEmail: "",
        buttonDisabled: true
    };

    const [state, dispatch] = useReducer(stateReducer, initState);

    useEffect(() => {
        setNavBarTitle(t('account.profile.info.navbar'));
    }, []);

    useEffect(() => {
        if (user) {
            initUserInfo.firstName = user?.firstName ? user.firstName : "";
            initUserInfo.lastName = user?.lastName ? user.lastName : "";
            initUserInfo.email = user?.email ? user.email : "";
            dispatch({ ...initUserInfo });
        }
    }, [user]);


    const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        const { firstName, lastName, email } = state;

        if (!firstName || !email) {
            dispatch({
                errorFirstName: firstName ? "" : t('error.required'),
                errorEmail: email ? "" : t('error.required'),
            });
        } else {
            dispatch({ buttonDisabled: true });

            if (userAuth) {
                updateUser(userAuth.uid, email, firstName, lastName).then(() => {
                    dispatch({ buttonDisabled: true });
                    reloadUserInfo();

                    // Changes in user auth (only if needed)
                    if (firstName !== initUserInfo.firstName || lastName !== initUserInfo.lastName) {
                        const displayName = lastName ? `${firstName} ${lastName}` : firstName;
                        updateUserName(displayName)?.then(() => {
                            addToQueue(t('account.profile.info.update.name'));
                        });
                    }

                    // Changes in user auth (only if needed)
                    if (email !== initUserInfo.email) {
                        updateUserEmail(email)?.then(() => {
                            addToQueue(t('account.profile.info.update.email'));
                        });
                    }

                }).catch(() => {
                    dispatch({ buttonDisabled: false });
                    addToQueue(t('account.profile.info.update.error'));
                });
            }
        }

    };

    const handleChange = (fieldChanged: "firstName" | "lastName" | "email", event: any) => {
        const newValue = event.target.value;

        // If the new value and the original value are different, the button to update info is available
        dispatch({ [fieldChanged]: newValue, buttonDisabled: !(newValue !== initUserInfo[fieldChanged]) });
    };

    const resetErrors = () => {
        dispatch({ errorFirstName: "", errorEmail: "" });
    };

    const handleLogOut = () => signOut(history);

    return (
        <div>
            <SectionTitle>{t('account.profile.info.title')}</SectionTitle>
            <Container>
                <TextFieldStyle><TextField hint={t('account.profile.info.firstName')}
                    onChange={(e) => {
                        handleChange("firstName", e);
                        resetErrors();
                    }}
                    error={state.errorFirstName}
                    value={state.firstName} /></TextFieldStyle>
                <TextFieldStyle><TextField hint={t('account.profile.info.lastName')}
                    onChange={(e) => {
                        handleChange("lastName", e);
                    }}
                    value={state.lastName} /></TextFieldStyle>
                <TextFieldStyle><TextField hint={t('account.profile.info.email')}
                    onChange={(e) => {
                        handleChange("email", e);
                        resetErrors();
                    }}
                    error={state.errorEmail}
                    value={state.email} /></TextFieldStyle>
                <ButtonContainer><Button colorStyle={IButtonStyle.action} disabled={state.buttonDisabled}
                    handleClick={(e) => handleSubmit(e)}
                    label={t('account.profile.info.update')} /></ButtonContainer>
            </Container>

            <SectionTitle>{t('account.profile.session')}</SectionTitle>
            <Container>
                <DisconnectionSection>
                    {t('account.profile.session.disconnection')}
                    <Button handleClick={() => handleLogOut()} colorStyle={IButtonStyle.negative}
                        label={t('account.profile.session.button')} />
                </DisconnectionSection>
            </Container>
            <Delete />
        </div>
    );
};

const Container = style.div`
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 30px 36px;
  & > form > button {
    margin-top: 30px;
`;

const TextFieldStyle = style.div`
  margin-bottom: 15px;
`;

const ButtonContainer = style.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const DisconnectionSection = style.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ${props => props.theme.body.desktop}
`;

export default Profile;