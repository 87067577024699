import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { withTheme, ThemeProvider } from 'styled-components';

import Container from './container';
interface Props {
  theme: any,
}
const Modal: (props:Props) => null = ({ theme }) => {
  useEffect(() => {
    ReactDOM.render(
      <ThemeProvider theme={theme} >
        <Container/>
      </ThemeProvider>,
      document.getElementById('modal')
    );
  }, [theme]);
  return null;
};

export default withTheme(Modal);