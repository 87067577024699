import { TransitionStyle } from "./type";

export const duration = 300;

export const lifeDuration = 5000;

export const transitionStyles = {
  [TransitionStyle.entering]: { 
    opacity: 0,
  },
  [TransitionStyle.entered]:  { 
    opacity: 1,
  },
  [TransitionStyle.exiting]:  { opacity: 0 },
  [TransitionStyle.exited]:  { opacity: 0 },
};