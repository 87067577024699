import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import style from 'styled-components';
import ToolTip from '../ToolTip';

interface ITagProps {
    label: string;
    description?: string;
    color?: string;
}

const Tag = ({ label, description, color = "" }: ITagProps) => {
    return (
        <TagContainer>
            <TagLabel data-tip={description || ""}>{label}</TagLabel>
            <ReactTooltip place="top" type="dark" effect="solid">
                {description || ""}
            </ReactTooltip>
            <ToolTip label={description || ""} />
        </TagContainer>
    );
};

const TagContainer = style.div`
    background-color: ${props => props.theme.primary};
    display: flex;
    height: 20px;
    padding: 0px 8px;
    border-radius: 4px;
    ${props => props.theme.noSelect}
    ${props => props.theme.transition}
`;

const TagLabel = style.p`
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 800;
    color: ${props => props.theme.surface}
`;

export default Tag;