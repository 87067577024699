import React from 'react';
import Style from 'styled-components';
import Button from '../Button';

interface IListCardProps {
    key: string,
    onClick?: () => void,
    imgSource?: string,
    text?: string,
    subText?: string,
    actionLabel?: string
}

export const ListCard: React.FC<IListCardProps> = ({ key, onClick, imgSource, text, subText, actionLabel }): JSX.Element => {
    return (
        <ContainerBase>
            <LeftContainer>
                <Image src={imgSource} />
                <TextContent>
                    <CardTitle>{text}</CardTitle>
                    <CardSubTitle>{subText}</CardSubTitle>
                </TextContent>
            </LeftContainer>
            <RightContainer>
                <Button label={actionLabel} handleClick={onClick} />
            </RightContainer>
        </ContainerBase>
    );
}

const Image = Style.img`
    height: 62px;
    width: 62px;
    margin: auto 18px auto 0;
`;

const CardTitle = Style.p`
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 8px;
    ${props => props.theme.paragraphTitle.desktop}
`;

const CardSubTitle = Style.p`
    color: ${props => props.theme.onBackground};
    font-size: 24px;
    margin: 0;
    ${props => props.theme.information.desktop}
`;

const ContainerBase = Style.div`
    padding: 24px 36px;
    margin-bottom: 24px;
    background-color: ${props => props.theme.surface};
    border-radius: 8px; 
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.2s;
    display: flex;
    flex-direction: horizontal;
    width: -webkit-fill-available;
    justify-content: space-between;
    &:hover {
        box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.25);
    }
`;

const LeftContainer = Style.div`
    display: flex;
    flex-direction: column;
    flex-direction: row;
`;

const RightContainer = Style.div`
    display: flex;
    flex-direction: column;
    margin: auto 0;
`;

const TextContent = Style.div`
    display: flex;
    flex-direction: column;
    margin: auto 0;
`;