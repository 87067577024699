import React from "react";
import Breadcrumb from '../Breadcrumb';

interface BreadcrumbIdeaProps {
    organisationLabel: string,
    organisationLink: string,
    boxLabel: string,
    boxLink?: string,
    ideaLabel?: string,
    loading: boolean,
}

const BreadcrumbIdea: React.FC<BreadcrumbIdeaProps> = ({ organisationLabel, organisationLink, boxLabel, loading, boxLink, ideaLabel }): JSX.Element => {
    const labels = [
        {
            text: "Accueil",
            link: "../",
        },
        {
            text: organisationLabel,
            link: organisationLink,
        },
        {
            text: boxLabel,
            link: boxLink
        },
        {
            text: ideaLabel || ''
        }
    ]
    return <Breadcrumb loading={loading} labels={labels} />
}

export default BreadcrumbIdea;