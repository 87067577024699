import * as firebase from 'firebase';
import { useState } from 'react';
import * as H from 'history';
import { createUser, deleteUser } from '../repositories/userRepository';

let userGlobal: firebase.User | null = null;

const useAuth: Function = (): firebase.User | null => {
    const [user, updateUser] = useState<firebase.User | null>(userGlobal);
    firebase.auth().onAuthStateChanged(userAuth => {
        updateUser(userAuth);
        userGlobal = userAuth;
    });
    return user;
};

export const signIn: (email: string, password: string) => Promise<void | null> =
    (email: string, password: string) => {
        const userAccount = firebase.auth().currentUser;
        return !userAccount ?
            firebase.auth().signInWithEmailAndPassword(email, password).then(() => null)
            :
            userAccount.reauthenticateWithCredential(
                firebase.auth.EmailAuthProvider.credential(email, password)
            ).then(() => null);
        ;
    }

export const signUp: (email: string, password: string, firstName: string, lastName: string, then: () => void) => Promise<void | null> =
    (email, password, firstName, lastName, then) =>
        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((res) => {
                createUser(res.user?.uid || "", email, firstName, lastName).then(() => {
                    then();
                });
            });

export const signOut = (history: H.History<H.LocationState>) => {
    firebase.auth().signOut().then(() => history.push('/'))
};

export const deleteAccount: () => Promise<void> | undefined
    = () => {
        const currentUser = firebase.auth().currentUser;
        return currentUser?.delete().then(() => deleteUser(currentUser.uid));
    }

export const updateUserName: (newName: string) => Promise<void> | undefined
    = (newName: string) => {
        return firebase.auth().currentUser?.updateProfile({
            displayName: newName,
        });
    };

export const updateUserEmail: (newEmail: string) => Promise<void> | undefined
    = (newEmail: string) => {
        return firebase.auth().currentUser?.updateEmail(newEmail);
    };

export default useAuth;

/**
Ex :
function App() {
  const [user, { signIn }] = useAuth();
  useEffect(() => {
    signIn('a@a.fr', 'aaaaaaaaaaaa');
  }, [signIn]);
  console.log('Hello ', { user });
  return null;
}
 */