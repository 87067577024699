import React, { useContext } from 'react';
import Style, { ThemeContext } from 'styled-components';
import { Icon, IconType } from '../Icon';

const Chevron: React.FC = (): JSX.Element => {
    const themeContext = useContext(ThemeContext);

    return (
        <Container>
            <Icon iconType={IconType['chevron-right']} size={16} color={themeContext.onBackground} />
        </Container>
    )
}

export default Chevron;

const Container = Style.span`
    display: inline-block;
    margin-right: 4px;
    margin-left: 6px;
    position: relative;
    top: 2px;
`