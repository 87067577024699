import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Style from 'styled-components';
import Button, { IButtonStyle, IWidth } from '../../../components/Button';
import { IconType } from '../../../components/Icon';
import { requestModalOpening } from '../../../components/Modal/utils';
import TextField from '../../../components/TextField';
import { sorts, updateSort, Filter } from '../../../utils/firebase/useFilteredIdeas';
import { Box, Organization, User } from '../../../utils/types/type';
import CreateBox from './components/CreateBox';

interface Props {
    box?: Box,
    user?: User,
    organizationId?: string,
    organizationName?: string,
}

const Desc: (props: Props) => JSX.Element = ({ box, user, organizationName, organizationId }) => {
    const [text, updateText] = useState<string>('');
    const [activeSorts, updateActiveSorts] = useState<Filter[]>([sorts.dateSort, sorts.defaultSort]);
    const { t } = useTranslation();

    useEffect(() => {
        updateSort([sorts.containSort(text), ...activeSorts]);
    }, [text, activeSorts]);

    const addIdea = () => {
        if (box) {
            requestModalOpening(CreateBox, { user, organizationName, organizationId, box })
        }
    }

    return (
        <Container>
            <Title>{t('box.list.search.title')}</Title>
            <Info>{t('box.list.search.description', { organizationName: box?.organization?.name })}</Info>
            <TextField onChange={(v) => updateText(v.target.value)} value={text} icon={IconType.search} placeholder={t('box.list.search.search.placeholder')} marginBottom={12} />
            <SelectionContainer>
                {t('box.list.search.sort.title')}
                <select onChange={(e) => updateActiveSorts([e.target.value === "Date" ? sorts.dateSort : sorts.likeSort, activeSorts[1]])}>
                    <option value="Date">{t('box.list.search.sort.value.date')}</option>
                    <option value="Like">{t('box.list.search.sort.value.like')}</option>
                </select>
                {t('box.list.search.status.title')}
                <select onChange={(e) => updateActiveSorts([activeSorts[0], e.target.value === "Neutral" ? sorts.defaultSort : sorts.dateSort])}>
                    <option value="Neutral">{t('box.list.search.status.value.all')}</option>
                    <option value="Active">{t('box.list.search.status.value.active')}</option>
                </select>
            </SelectionContainer>
            <Button label={t('box.list.search.add')} colorStyle={IButtonStyle.positive} iconLeft={IconType['plus-rounded']} width={IWidth['match-parent']} handleClick={() => addIdea()} />
        </Container>
    );
}

const Container = Style.div`
    background-color: ${({ theme }) => theme.surface};
    padding: ${({ theme }) => theme.medium.desktop};
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    max-width: 302px;
`;

const SelectionContainer = Style.div`
  margin: ${({ theme }) => theme.medium.desktop} 0;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  color: ${({ theme }) => theme.onSurface};

  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: ${({ theme }) => theme.small.desktop};
  row-gap: ${({ theme }) => theme.small.desktop};
  align-items: center;

  & select {
    /* Background Medium */

    background: #EAEAEA;
    /* Background Medium */

    border: 3px solid #EAEAEA;
    box-sizing: border-box;
    border-radius: 4px;

    height: 32px;
  }
`;

const Title = Style.div`
    ${({ theme }) => theme.heading.desktop};
    margin-bottom: ${({ theme }) => theme.regular.desktop};
`;

const Info = Style.div`
    ${({ theme }) => theme.information.desktop};
    margin-bottom: ${({ theme }) => theme.medium.desktop};
    color: ${({ theme }) => theme.onBackground};
`;

export default Desc;