import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {Organization} from "../../../utils/types/type";
import {
    getOrganization,
    updateOrganizationContact,
    updateOrganizationGeneral
} from "../../../utils/repositories/organizationsRepository";
import SectionTitle from "../../../components/SectionTitle";
import Style from "styled-components";
import TextField from "../../../components/TextField";
import Button, {IButtonStyle} from "../../../components/Button";
import {addToQueue} from "../../../components/Toast/utils";

const fieldsMandatory = ["name", "summary", "description"];

const Edit = () => {
    const {t} = useTranslation();
    const {id} = useParams<{ id: string }>();
    const [organization, updateOrganization] = useState<Organization | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errors, setErrors] = useState<Record<string, string>>({});

    useEffect(() => {
        (async () => {
            const organization = await getOrganization(id);
            updateOrganization(organization);
            setIsLoading(false);
        })();
    }, [id]);

    const handleChange = (event: any, field: string) => {
        const newValue = event.target.value;
        const newOrga: Organization | null = organization ? {...organization, [field]: newValue} : null;

        if (newValue === "" && fieldsMandatory.includes(field)) {
            setErrors({...errors, [field]: t('error.required')});
        } else {
            if (errors[field] !== "") {
                setErrors({...errors, [field]: ""});
            }
        }

        updateOrganization(newOrga);
    };

    const handleSubmitGeneral = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        if (organization) {
            const {id, name, summary, description} = organization;

            if (id && name && summary && description) {
                updateOrganizationGeneral(id, name, summary, description).then(() => {
                    addToQueue(t('organization.edit.infos.update'));
                }).catch(() => {
                    addToQueue(t('organization.edit.update.error'));
                });
            }
        }
    };

    const handleSubmitContact = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        if (organization) {
            const {id, contactEmail, contactPhone, contactWebsite} = organization;

            if (id && contactEmail && contactPhone && contactWebsite) {
                updateOrganizationContact(id, contactEmail, contactPhone, contactWebsite).then(() => {
                    addToQueue(t('organization.edit.contact.update'));
                }).catch(() => {
                    addToQueue(t('organization.edit.update.error'));
                });
            }
        }
    };

    return (
        <>
            {organization &&
            <div style={{paddingBottom: 30}}>
                <SectionTitle>{t('organization.edit.info.title')}</SectionTitle>
                <Container>
                    <TextFieldStyle>
                        <TextField hint={t('organization.create.field')}
                                   onChange={(e) => handleChange(e, "name")}
                                   value={organization.name ? organization.name : ""}
                                   error={errors.name}
                        />
                    </TextFieldStyle>
                    <TextFieldStyle>
                        <TextField
                            hint={t('organization.edit.description.courte')}
                            onChange={(e) => handleChange(e, "summary")}
                            value={organization.summary ? organization.summary : ""}
                            error={errors.summary}
                            maxLength={400}
                            maxLines={4}
                        />
                    </TextFieldStyle>
                    <TextFieldStyle>
                        <TextField
                            hint={t('organization.edit.description.longue')}
                            onChange={(e) => handleChange(e, "description")}
                            value={organization.description ? organization.description : ""}
                            error={errors.description}
                            maxLength={800}
                            maxLines={4}
                        />
                    </TextFieldStyle>
                    <ButtonContainer><Button colorStyle={IButtonStyle.primary}
                                             handleClick={(e) => handleSubmitGeneral(e)}
                                             label={t('account.profile.info.update')}/></ButtonContainer>
                </Container>

                <SectionTitle>{t('organization.edit.contact.title')}</SectionTitle>
                <Container>
                    <InfoText>{t('organization.edit.contact.info')}</InfoText>
                    <TextFieldStyle>
                        <TextField hint={t('organization.edit.contact.email')}
                                   onChange={(e) => handleChange(e, "contactEmail")}
                                   value={organization.contactEmail ? organization.contactEmail : ""}
                                   error={errors.contactEmail}
                        />
                    </TextFieldStyle>
                    <TextFieldStyle>
                        <TextField
                            hint={t('organization.edit.contact.phone')}
                            onChange={(e) => handleChange(e, "contactPhone")}
                            value={organization.contactPhone ? organization.contactPhone : ""}
                            error={errors.contactPhone}
                        />
                    </TextFieldStyle>
                    <TextFieldStyle>
                        <TextField
                            hint={t('organization.edit.contact.website')}
                            onChange={(e) => handleChange(e, "contactWebsite")}
                            value={organization.contactWebsite ? organization.contactWebsite : ""}
                            error={errors.contactWebsite}
                        />
                    </TextFieldStyle>
                    <ButtonContainer><Button colorStyle={IButtonStyle.primary}
                                             handleClick={(e) => handleSubmitContact(e)}
                                             label={t('account.profile.info.update')}/></ButtonContainer>
                </Container>
            </div>
            }

            {!organization &&
            <div style={{textAlign: "center"}}>
                {isLoading ? (
                    <Spinner className="spinner"/>
                ) : (
                    <p>{t('organization.error.not-found')}</p>
                )}
            </div>
            }
        </>
    );
};

export default Edit;

const Spinner = Style.span`
    height: 25px;
    width: 25px;
`;

const TextFieldStyle = Style.div`
  margin-bottom: 15px;
`;

const Container = Style.div`
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 30px 36px;
  margin-bottom: 36px;
`;

const ButtonContainer = Style.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const InfoText = Style.div`
    margin-bottom: 20px;
    ${props => props.theme.body.desktop}
`;