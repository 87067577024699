import React, { ChangeEvent, useContext, useState } from 'react';
import Style from 'styled-components';
import { Icon, IconType } from '../Icon';
import { ThemeContext } from 'styled-components';

interface ITextFieldProps {
    hint?: string;
    placeholder?: string;
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    value: string;
    type?: string;
    error?: string | null;
    autocomplete?: string;
    icon?: IconType;
    autofocus?: boolean;
    marginBottom?: number;
    maxLength?: number;
    maxLines?: number;
}

const TextField = ({
    hint = '',
    placeholder = '',
    onChange,
    value,
    type = 'text',
    error,
    autocomplete = 'on',
    icon,
    autofocus = false,
    marginBottom,
    maxLength,
    maxLines
}: ITextFieldProps): JSX.Element => {
    const themeContext = useContext(ThemeContext);
    const [currentValue, setCurrentValue] = useState<string>("");

    const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(event.target.value);
        onChange(event);
    }

    const handleValueChangeTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setCurrentValue(event.target.value);
        onChange(event);
    }

    return (
        <div>
            <Title>
                <Name>{hint}</Name>
                {error && <Error>{error}</Error>}
                {maxLength && !error && <CharacterCounter>{(maxLength - currentValue.length).toString()}</CharacterCounter>}
            </Title>
            {icon && <IconSpan><Icon iconType={icon} color={themeContext.onVariant} /></IconSpan>}
            {maxLines ?
                (<TextArea
                    onChange={handleValueChangeTextArea}
                    rows={maxLines}
                    autoFocus={autofocus}
                    placeholder={placeholder}
                    style={{ borderColor: error ? themeContext.error : '', padding: icon ? '0px 14px 0px 42px' : '8px  14px', marginBottom: marginBottom || 0 }}
                    autoComplete={autocomplete}
                    maxLength={maxLength || 10000}>
                    {value}
                </TextArea>)
                : (<Input
                    type={type}
                    onChange={handleValueChange}
                    value={value}
                    autoFocus={autofocus}
                    placeholder={placeholder}
                    style={{ borderColor: error ? themeContext.error : '', padding: icon ? '0px 14px 0px 42px' : '0px  14px', marginBottom: marginBottom || 0 }}
                    autoComplete={autocomplete}
                    maxLength={maxLength || 10000} />)}
        </div>
    );
};

export default TextField;

const Title = Style.div`
  display: flex;
  justify-content: space-between;
`;

const IconSpan = Style.span`
    position: absolute;
    margin-left: 18px;
    height: ${props => props.theme.huge.desktop};
    display: flex;
    align-items: center;
    width: ${props => props.theme.medium.desktop};
    color: ${props => props.theme.onVariant}
`;

const Name = Style.div`
  font-size: 1rem !important;
  ${props => props.theme.information.desktop};
  color: ${props => props.theme.onBackground};
  margin-bottom: .3rem;
  ${props => props.theme.noSelect};
`;

const Error = Style.div`
  ${props => props.theme.information.desktop};
  text-align: right;
  color: ${props => props.theme.error};
  ${props => props.theme.noSelect};
`;

const CharacterCounter = Style.div`
  ${props => props.theme.information.desktop};
  text-align: right;
  color: ${props => props.theme.onBackground};
  ${props => props.theme.noSelect};
`;

const Input = Style.input`
  background-color: ${props => props.theme.onSurface};
  border-radius: .5rem;
  background-color: ${props => props.theme.backgroundLight};
  height: ${props => props.theme.huge.desktop};
  ${props => props.theme.body.desktop};
  font-family: inherit;
  font-size: 18px;
  transition: 0.1s;
  border: solid .3rem ${props => props.theme.onVariant};
  &:focus {
    border: solid .3rem ${props => props.theme.primary};
    ${props => props.theme.noOutline};
    background-color: ${props => props.theme.surface};
  }
  ::placeholder {
    color: ${props => props.theme.onVariant};
  }
  width: 100%;
  box-sizing: border-box;
`;

const TextArea = Style.textarea`
  background-color: ${props => props.theme.onSurface};
  border-radius: .5rem;
  background-color: ${props => props.theme.backgroundLight};
  min-height: ${props => props.theme.huge.desktop};
  ${props => props.theme.body.desktop};
  font-family: inherit;
  font-size: 18px;
  padding-top:12px;
  resize: none;
  transition: 0.1s;
  border: solid .3rem ${props => props.theme.onVariant};
  &:focus {
    border: solid .3rem ${props => props.theme.primary};
    ${props => props.theme.noOutline};
    background-color: ${props => props.theme.surface};
  }
  ::placeholder {
    color: ${props => props.theme.onVariant};
  }
  width: 100%;
  box-sizing: border-box;
`;