import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Style, { ThemeContext, useTheme } from 'styled-components';
import Button, { IButtonStyle, IWidth } from '../../../components/Button';
import { Icon, IconType } from '../../../components/Icon';
import Avatar, { IAvatarType } from '../../../components/Avatar';
import { requestModalOpening } from '../../../components/Modal/utils';
import TextField from '../../../components/TextField';
import { sorts, updateSort, Filter } from '../../../utils/firebase/useFilteredIdeas';
import { Box, Idea, Organization, User } from '../../../utils/types/type';
import Tag from '../../../components/Tag';
import { isIdeaLiked, likeIdea, unlikeIdea, archiveIdea } from '../../../utils/repositories/IdeaRepository';
import { addToQueue } from '../../../components/Toast/utils';
import { useLocation } from 'react-router-dom';
import { getAuthorInitials, isCurrentUserAuthor } from '../../../utils/repositories/userRepository';

interface Props {
    idea: Idea,
    user?: User,
}

const Desc: (props: Props) => JSX.Element = ({ idea, user }) => {
    const themeContext = useContext(ThemeContext);
    const [text, updateText] = useState<string>('');
    const [activeSorts, updateActiveSorts] = useState<Filter[]>([sorts.dateSort, sorts.defaultSort]);
    const [likeButtonHover, setLikeButtonHover] = useState<boolean>(false);
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        updateSort([sorts.containSort(text), ...activeSorts]);
    }, [text, activeSorts]);

    const [liked, setLiked] = useState<boolean>(false);

    useEffect(() => {
        user && isIdeaLiked(idea.id, user.id).then(res => setLiked(res))
    }, [idea.id, user])

    const handleLikeHover = (isHovered: boolean) => {
        setLikeButtonHover(isHovered);
    }

    const handleLike = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        if (liked) {
            idea.statistics.nbOfLikes--;
            user && unlikeIdea(idea.id, user.id, idea.box.id).then(() =>
                isIdeaLiked(idea.id, user.id).then(res => {
                    setLiked(res)
                    addToQueue(t('idea.like.action.liked'))
                }))
        } else {
            idea.statistics.nbOfLikes++;
            user && likeIdea(idea.id, user.id, user.firstName, user.lastName || '', idea.box.id).then(() => isIdeaLiked(idea.id, user.id).then(res => {
                addToQueue(t('idea.like.action.unliked'))
                setLiked(res)
            }));
        }
    }

    const handleShare = () => {
        navigator.clipboard.writeText(window.location.href).then(function () {
            addToQueue(t('idea.like.action.share.copied'));
        }, function () {
            addToQueue(t('idea.error.clipboard'));
        });
    }

    const handleArchive = () => user && archiveIdea(idea.id, idea.box.id).then(() => {
        window.location.href = `/b/${idea.box.id}`;
        addToQueue(t('idea.like.action.archived'));
    })

    return (
        <Container>
            <BasicDesc>
                <Avatar initials={getAuthorInitials(idea.author.firstName, idea.author.lastName)} name="" type={IAvatarType.large} />
                <div>{idea.author.firstName} {idea.author.lastName && `${idea.author.lastName.substr(0, 1).toUpperCase()}.`}</div>
                <div>{t('idea.detail.content.publishedAt')} {moment(idea.creationDate).format('DD MMMM à HH')}h{moment(idea.creationDate).format('mm')}</div>
                {idea.ideaStatus && <Tag label={idea.ideaStatus?.label || ""} description={idea.ideaStatus?.description || ""} />}
            </BasicDesc>
            <StatsContainer>
                <StatsItem>
                    <Icon iconType={IconType.eye} color={themeContext.onVariant} size={28} />
                    <StatsText>{idea.statistics.nbOfView}</StatsText>
                </StatsItem>
                <StatsItem>
                    <Icon iconType={IconType.heart} color={themeContext.onVariant} size={24} />
                    <StatsText>{idea.statistics.nbOfLikes}</StatsText>
                </StatsItem>
                <StatsItem>
                    <Icon iconType={IconType.comment} color={themeContext.onVariant} size={24} />
                    <StatsText>{idea.statistics.nbOfComments}</StatsText>
                </StatsItem>
            </StatsContainer>
            {!liked ? (
                <Button label={t('idea.detail.action.like')} colorStyle={IButtonStyle.negative} iconLeft={IconType['heart']} width={IWidth['match-parent']} handleClick={handleLike} />
            ) : (
                    <Button label={likeButtonHover ? t('idea.detail.action.unlike.hover') : t('idea.detail.action.unlike')} colorStyle={IButtonStyle['negative-active']} iconLeft={IconType['heart']} width={IWidth['match-parent']} handleClick={handleLike} handleHover={handleLikeHover} />
                )}
            <Divider />
            <Button label={t('idea.detail.action.share')} colorStyle={IButtonStyle.action} iconLeft={IconType['link']} width={IWidth['match-parent']} handleClick={handleShare} />
            {
                isCurrentUserAuthor(user, idea) && (<>
                    <p>{t('idea.detail.admin.title')}</p>
                    <Button label={t('idea.like.action.archive')} colorStyle={IButtonStyle.negative} iconLeft={IconType.trash} width={IWidth['match-parent']} handleClick={handleArchive} />
                </>)
            }
        </Container>
    );
}

const Container = Style.div`
    background-color: ${({ theme }) => theme.surface};
    padding: ${({ theme }) => theme.medium.desktop};
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    max-width: 302px;
`;

const Divider = Style.div`
    height: 12px;
`;

const StatsContainer = Style.div`
    display: flex;
    justify-content: space-around;
    width: 200px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 32px;
`;

const StatsItem = Style.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 62px;
    justify-content: space-between;
`;

const StatsText = Style.p`
    color: ${s => s.theme.onVariant};
    font-size: 18px;
    font-weight: 600;
    margin-top: 12px;
    margin-bottom: 0px;
`;

const BasicDesc = Style.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
    & > *:not(:first-child) {
        margin-top: ${({ theme }) => theme.regular.desktop};
    }
    & > *:nth-child(2) {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 17px;
    }
    & > *:nth-child(3) {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 15px;
        color: ${({ theme }) => theme.onVariant};
    }
`;

const SelectionContainer = Style.div`
  margin: ${({ theme }) => theme.medium.desktop} 0;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  color: ${({ theme }) => theme.onSurface};

  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: ${({ theme }) => theme.small.desktop};
  row-gap: ${({ theme }) => theme.small.desktop};
  align-items: center;

  & select {
    /* Background Medium */

    background: #EAEAEA;
    /* Background Medium */

    border: 3px solid #EAEAEA;
    box-sizing: border-box;
    border-radius: 4px;

    height: 32px;
  }
`;

const Title = Style.div`
    ${({ theme }) => theme.heading.desktop};
    margin-bottom: ${({ theme }) => theme.regular.desktop};
`;

const Info = Style.div`
    ${({ theme }) => theme.information.desktop};
    margin-bottom: ${({ theme }) => theme.medium.desktop};
    color: ${({ theme }) => theme.onBackground};
`;

export default Desc;