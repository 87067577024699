import React from 'react';
import style from 'styled-components';

const SectionTitle: React.FC = ({ children }): JSX.Element => {
    return (
        <Container>
            {children}
        </Container>
    );
};

export default SectionTitle;

const Container = style.div`
    width: 100%;
    padding: 0;
    margin:25px 0px 12px 0px;
    ${props => props.theme.heading.desktop};
    color: ${props => props.theme.onBackground};
    ${props => props.theme.noSelect};
`;