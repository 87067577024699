import React, { useState } from 'react';
import style from 'styled-components';
import { useTranslation } from "react-i18next";
import { Modal as ModalType } from '../../../../../components/Modal/type';
import { requestModalClosing, requestModalOpening } from '../../../../../components/Modal/utils';
import Button, { IButtonStyle } from '../../../../../components/Button';
import ErrorModal from './ErrorModal';
import TextField from '../../../../../components/TextField';
import { createIdea } from '../../../../../utils/repositories/IdeaRepository';
import { addToQueue } from '../../../../../components/Toast/utils';

const CreateBox: ModalType = ({ clickedOutside, additionalProps: { user, organizationName, organizationId, box } }) => {
    const { t } = useTranslation();
    const [ideaTitle, setIdeaTitle] = useState<string>('');
    const [ideaTitleError, setIdeaTitleError] = useState<boolean>(false);
    const [ideaContent, setIdeaContent] = useState<string>('');
    const [ideaContentError, setIdeaContentError] = useState<boolean>(false);
    const [ideaCreationLoading, setIdeaCreationLoading] = useState<boolean>(false);

    if (clickedOutside) {
        requestModalClosing();
    }

    const handleTitleChange = (value: string) => {
        setIdeaTitle(value);
        setIdeaTitleError(false);
    }

    const handleContentChange = (value: string) => {
        setIdeaContent(value);
        setIdeaContentError(false);
    }

    const handleCreateIdea = () => {
        if (ideaTitle.length == 0) {
            setIdeaTitleError(true)
        }
        if (ideaContent.length == 0) {
            setIdeaContentError(true)
        }
        if (ideaTitle.length > 0 && ideaContent.length > 0) {
            if (user && organizationName && organizationId && box) {
                setIdeaCreationLoading(true);
                createIdea(ideaTitle, ideaContent, user, organizationName, organizationId, box).then(res => {
                    console.log("doc créé ! ", res);
                    addToQueue(t('idea.create.success'));
                    requestModalClosing();
                    // requestModalOpening(ErrorModal);
                }).catch(() => requestModalOpening(ErrorModal));
            } else {
                addToQueue("Une erreur est survenue lors de la création de l'idée")
            }
        }
    }

    return (
        <Container>
            <Title>
                {t('idea.create.title')}
            </Title>
            <Information>
                {t('idea.create.information')}
            </Information>
            <TextField hint={t('idea.create.textfield.title.hint')} placeholder={t('idea.create.textfield.title.placeholder')} onChange={(e) => handleTitleChange(e.target.value)} value={ideaTitle} maxLength={100} marginBottom={24} error={ideaTitleError ? t('idea.create.textfield.title.error') : null} />
            <TextField hint={t('idea.create.textfield.content.hint')} placeholder={t('idea.create.textfield.content.placeholder')} onChange={(e) => handleContentChange(e.target.value)} value={ideaContent} marginBottom={36} maxLength={3000} maxLines={10} error={ideaContentError ? t('idea.create.textfield.content.error') : null} />
            <ButtonContainer>
                <Button
                    label={t('button.cancel')}
                    handleClick={requestModalClosing}
                />
                <Button
                    label={t('button.create')}
                    colorStyle={IButtonStyle.positive}
                    handleClick={() => handleCreateIdea()}
                    loading={ideaCreationLoading}
                />
            </ButtonContainer>
        </Container>
    );
}

const Container = style.div`
  background: ${({ theme }) => theme.surface};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: ${({ theme }) => theme.large.desktop};
  padding-bottom: ${({ theme }) => theme.medium.desktop};
  max-width: 40vw;
`;

const Title = style.div`
  ${({ theme }) => theme.heading.desktop}
  margin-bottom: ${({ theme }) => theme.large.desktop};
  color: ${({ theme }) => theme.onSurfacePure};
`;

const Action = style.div`
  ${({ theme }) => theme.action.desktop}
  margin-bottom: ${({ theme }) => theme.medium.desktop};
  color: ${({ theme }) => theme.onSurface};
`;

const Information = style.div`
  margin-bottom: ${({ theme }) => theme.large.desktop};
  color: ${({ theme }) => theme.onBackground};
  ${({ theme }) => theme.information.desktop}
`;

const ButtonContainer = style.div`
  display: flex;
  justify-content: space-between;
`;

export default CreateBox;