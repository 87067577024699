import { Route } from '../../../utils/routes/routes';
import React from 'react';

import Overview from './Overview';
import { Organization } from '../../../utils/types/type';
import Edit from "./Edit";

type Keys = 'organization-home' | 'organization-edit' | 'organization-stats' | 'organization-voice';
const base: Route<Keys>[] = [
    {
        key: 'organization-home',
        path: '',
        // title: 'Home',
        Component: () => <Overview />,
        exact: true,
    },
    {
        key: 'organization-edit',
        path: '/edit',
        // title: 'Home',
        Component: () => <Edit/>,
    },
    {
        key: 'organization-stats',
        path: '/stats',
        // title: 'Home',
        Component: () => <div>Stats</div>,
    },
    {
        key: 'organization-voice',
        path: '/organizations',
        // title: 'Home',
        Component: () => <div>Promouvoir</div>
    },
];

export default (path: string, organization?: Organization): Route<Keys>[] => base.map(b => ({ ...b, path: `${path}${b.path}`, organization }));