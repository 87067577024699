import React, { useEffect } from 'react';
import style from 'styled-components';
import { popFromQueue } from './utils';
import { duration, lifeDuration } from './constants';
import { ToastUnitProps } from './type';

const ToastUnitContainer = style.div`
  background-color: var(--black);
  color: var(--white);
  padding: 0 24px;
  display: flex;
  font-weight: 500;
  align-items: center;
  margin: 24px;
  transition: all ${duration}ms ease-in-out;
  position: relative;
  box-sizing: border-box;
  height: 52px;
  border-radius: 27.5px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.23);
  background-color: var(--black);
  bottom: 0;
  width: fit-content;
`;

const ToastUnit: (props: ToastUnitProps) => JSX.Element = ({ text, style, toastStyle }) => {
    useEffect(() => {
        setTimeout(() => {
            popFromQueue();
        }, lifeDuration);
    }, []);

    return (
        <ToastUnitContainer style={style} >{text}</ToastUnitContainer>
    );
}
export default ToastUnit;