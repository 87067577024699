import React from 'react';
import style from 'styled-components';
import { Box } from '../../utils/types/type';
import ListBoxCard from '../Card/ListBoxCard';

interface BoxGridProps {
    boxes: Box[],
    keyPrefix?: string,
}

const BoxGrid: React.FC<BoxGridProps> = ({ boxes, keyPrefix = "all", children }) => {
    return (
        <BoxContainer>
            {boxes.map((box: any) => <ListBoxCard title={box.name} desc={box.summary} id={box.id} nbOfLikes={box.statistics.nbOfLikes} nbOfIdeas={box.statistics.nbOfIdeas} nbOfComments={box.statistics.nbOfComments}  key={`${keyPrefix}---${box.id}`} />)}
        </BoxContainer>
    )
};

export default BoxGrid;

const BoxContainer = style.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
`;