import { getDocument, createDocumentWithId, updateDocument } from '../database/databaseRepository';
import { mapDataToUser } from '../mapping/UserMapper';
import { Idea, User } from '../types/type';

export const getUser = async (userId: string) => {
    const doc = await getDocument('/users', userId);
    if (doc == null) {
        throw new Error('user not found');
    }
    return mapDataToUser(doc);
}

export const createUser = async (id: string, email: string, firstName: string, lastName?: string) => {
    const date = Date.now();
    try {
        await createDocumentWithId('/users', id, {
            id,
            email,
            firstName,
            lastName,
            creationDate: date,
            lastUpdateDate: date,
            status: 0
        })
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getUserInitials = (user: User | undefined | null): string => {
    return user ?
        (user.lastName ?
            user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase()
            : user.firstName.substring(0, 2).toUpperCase())
        : "";
}

export const getAuthorInitials = (firstName: string, lastName: string | null | undefined): string => {
    return (lastName ?
        firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
        : firstName.substring(0, 2).toUpperCase());
}

export const getUserName = (user: User | undefined | null): string => {
    return user ?
        (user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName)
        : "";
}

export const isCurrentUserAuthor = (user: User | undefined | null, idea: Idea | undefined | null) => {
    return user?.id == idea?.author.id;
}

export const deleteUser = async (id: string) => await updateDocument(`users/${id}`, { status: 3 });

export const updateUser = async (id: string, email: string, firstName: string, lastName?: string) =>
    await updateDocument(`/users/${id}`, {
        email: email,
        firstName: firstName,
        lastName: lastName,
        lastUpdateDate: Date.now()
    });