import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    resources: {
        fr: {
            translations: {
                // General
                "general.hint.email": "Email",
                "general.hint.firstname_or_pseudo": "Prénom ou pseudo",
                "general.hint.lastname_facultative": "Nom (facultatif)",
                "general.hint.password": "Mot de passe",

                // Home
                "home.navbar.title": "Accueil",
                "home.information.title": "Bienvenue 👋🏻",
                "home.search.title": "Une super idée en tête ? 💡",
                "home.search.placeholder": "Chercher une idée, une organisation…",
                "home.search.empty": "Pas de boîte à idées avec ce nom",
                "home.trends.title": "Ces boîtes à idées cartonnent 📦",

                // Navbar
                "account.navbar.title": "Mon compte",
                "general.navbar.connect": "Connexion",
                "account.navbar.menu.my_account": "Mon compte",
                "account.navbar.menu.my_organizations": "Mes organisations",
                "account.navbar.menu.logout": "Déconnexion",

                // Signin / Signup
                "signin.title": "Vos bonnes idées méritent d'être mises en lumière",
                "signin.catchphrase": "Donnez de la valeur à ce que vous pensez et misez sur l'intelligence collective.",
                "signin.form.title": "Content de vous revoir !",
                "signin.form.description": "Utilisez ce formulaire pour vous connecter.",
                "signin.form.signup": "Si vous n’avez pas encore de compte, ",
                "signin.form.signup.link": "cliquez ici.",
                "signin.form.connect": "Connexion",
                "signup.form.title": "Bienvenue !",
                "signup.form.description": "Utilisez ce formulaire pour créer votre compte, vous pouvez modifier vos informations à tout moment.",
                "signup.form.create": "Créer mon compte",
                "signup.form.terms": "En vous connectant, vous affirmez adhérer aux ",
                "signup.form.terms.link": "CGU du service.",
                "signup.form.signin.link": "Vous avez déjà un compte ?",

                // API Error
                "auth/invalid-email": "email invalide",
                "auth/user-disabled": "votre compte est désactivé",
                "auth/user-not-found": "nous n'avons pas trouvé votre compte",
                "auth/wrong-password": "mot de passe incorrect",
                "auth/email-already-in-use": "cette adresse est déjà associée à un compte",
                "auth/weak-password": "votre mot de passe doit être supérieur à 6 caractères",
                "auth/common-error": "une erreur est survenue",
                "auth/network-request-failed": "veuillez vérifier votre connexion à internet",

                // Error
                "error.required": "obligatoire",

                // Account menu
                "menu.account.title": "Menu",
                "menu.account.home": "Accueil",
                "menu.account.details": "Mon compte",
                "menu.account.stats": "Statistiques",
                "menu.account.organization": "Organisations",
                "menu.account.about": "À propos",

                // Account
                "account.profile.info.navbar": "Mon compte",
                "account.profile.info.title": "Informations personnelles",
                "account.profile.info.firstName": "Prénom (ou pseudo)",
                "account.profile.info.lastName": "Nom",
                "account.profile.info.email": "Email",
                "account.profile.info.update": "Mettre à jour",
                "account.profile.info.update.name": "Nom / prénom modifié(s) avec succès",
                "account.profile.info.update.email": "Email modifié avec succès",
                "account.profile.info.update.error": "Une erreur est survenue",
                "account.profile.session": "Session",
                "account.profile.session.disconnection": "Déconnexion du navigateur",
                "account.profile.session.deletion": "Compte utilisateur",
                "account.profile.session.button": "Me déconnecter",
                "account.profile.deletion.title": "Zone de danger",
                "account.profile.deletion.body": "Supprimer mon compte",
                "account.profile.deletion.modal.heading": "Attention !",
                "account.profile.deletion.modal.action": "Vous êtes sur le point de supprimer définitivement votre compte.",
                "account.profile.deletion.modal.information": "En cliquant sur “Confirmer”, vous affirmez avoir pris conscience du caractère irréversible de cette action.",
                "account.profile.deletion.modal.error": "Veuillez vous reconnecter et retenter l'action : ",
                "account.profile.deletion.modal.link.login": "Connexion",

                // Account organizations
                "account.profile.organizations.navbar": "Mes organisations",
                "account.profile.organizations.title": "Les organisations dont je fais partie",
                "account.profile.organizations.list.manage": "Administrer",
                "account.profile.organizations.list.add": "Créer une nouvelle organisation",
                "account.profile.organizations.list.empty": "Vous n'êtes encore rattaché à aucune organisation.",

                // Button actions
                "button.cancel": "Annuler",
                "button.confirm": "Confirmer",
                "button.create": "Créer",

                // Box list
                "box.list.search.title": "Description",
                "box.list.placeholder": "Aucune idée en vue, et si vous la crééiez ?",
                "box.list.search.description": "Cette boîte à idée regroupe toutes les idées qui ont été proposées dans les différentes boîtes de {{organizationName}}.",
                "box.list.search.search.placeholder": "Chercher un mot, date...",
                "box.list.search.add": "Proposer une idée",
                "box.list.search.sort.title": "Tri",
                "box.list.search.sort.value.date": "Date",
                "box.list.search.sort.value.like": "Popularité",
                "box.list.search.status.title": "Statut",
                "box.list.search.status.value.all": "Tout",
                "box.list.search.status.value.active": "Actif",

                // Idea creation
                "idea.create.title": "Ajouter une idée",
                "idea.create.information": "Avant de créer votre idée, vérifiez qu'elle n'existe pas déjà dans cette boîte à idées.",
                "idea.create.textfield.title.hint": "Titre",
                "idea.create.textfield.title.placeholder": "Votre super idée, en quelques mots",
                "idea.create.textfield.title.error": "Vous devez renseigner un titre",
                "idea.create.textfield.content.hint": "Description",
                "idea.create.textfield.content.placeholder": "Toutes les idées sont bonnes, dites-nous en plus !",
                "idea.create.textfield.content.error": "Vous devez renseigner une description",
                "idea.create.success": "Votre idée a bien été créée !",

                // Idea actions
                "idea.like.action.liked": "Votre vote a bien été pris en compte !",
                "idea.like.action.unliked": "Votre vote a bien été pris en compte !",
                "idea.like.action.share.copied": "Le lien a été copié dans votre presse-papier !",
                "idea.like.action.archive": "Archiver cette idée",
                "idea.like.action.archived": "Cette idée a bien été archivée !",

                // Idea errors
                "idea.error.not-found": "Cette idée n'existe pas",
                "idea.error.clipboard": "Ce lien ne peut pas être",

                // Idea detail
                "idea.detail.content.title": "Description",
                "idea.detail.comments.title": "Commentaires",
                "idea.detail.content.publishedAt": "Publié le ",
                "idea.detail.comment.title": "Commentaires",
                "idea.detail.comment.validate": "Envoyer",
                "idea.detail.action.like": "Aimer cette idée",
                "idea.detail.action.share": "Partager",
                "idea.detail.action.unlike": "J'aime cette idée",
                "idea.detail.action.unlike.hover": "Ne plus aimer cette idée",

                // Idea detail - admin
                "idea.detail.admin.title": "Administration",

                // Organization menu
                "menu.organization.title": "Menu",
                "menu.organization.home": "Vue d'ensemble",
                "menu.organization.edit": "Editer",
                "menu.organization.stats": "Statistiques",
                "menu.organization.voice": "Organisations",
                "menu.organization.create": "Créer une boîte",
                "organization.error.not-found": "Cette organisation n'existe pas",

                // Box creation
                "box.create.title": "Créer une boîte à idées",
                "box.create.desc": "Créez une boîte à idées publique sous votre organisation. Les utilisateurs de Brusketa pourront y ajouter des idées et vous pourrez consulter les statistiques d’utilisation.",
                "box.create.field": "Nom",
                "box.create.resume": "Résumé",
                "box.create.cancel": "Annuler",
                "box.create.validate": "Créer et accéder",
                "box.create.error": "Une erreur est survenue lors de la création de cette boîte",

                "organization.overview.box": "Boîtes à idées",
                "organization.create.title": "Créer une organisation",
                "organization.create.desc": "Créez votre organisation, puis publiez des boîtes à idées ! Vous pouvez changer à tout moment vos informations.",
                "organization.create.warning": "Attention, si votre organisation possède une image de marque, vous devez être en mesure de justifier sa propriété.",
                "organization.create.field": "Nom de l’organisation",
                "organization.create.cancel": "Annuler",
                "organization.create.validate": "Créer et accéder",

                "organization.edit.info.title": "Informations générales 🏡",
                "organization.edit.description.courte": "Description courte",
                "organization.edit.description.longue": "Description longue",
                "organization.edit.status" : "Étiquettes de statut 🏷",
                "organization.edit.status.info" : "Les étiquettes de statut sont assignées par l’organisation aux idées de ses boîtes à idées. Elles permettent de donner des informations aux lecteurs des idées sur leur prise en compte. Exemple : en cours, mis en œuvre, etc.",
                "organization.edit.contact.title" : "Contact ✉️",
                "organization.edit.contact.info" : "Toutes les informations suivantes sont publiques et peuvent donc être consultées par tous les visiteurs du profil de votre organisation.",
                "organization.edit.contact.email" : "Email de contact",
                "organization.edit.contact.phone" : "Téléphone",
                "organization.edit.contact.website" : "Site internet",
                "organization.edit.infos.update" : "Informations générales modifiées avec succès",
                "organization.edit.contact.update" : "Informations de contact modifiées avec succès",
                "organization.edit.update.error" : "Une erreur est survenue lors de la modification",
                "organization.edit.image.title" : "Identité visuelle 📸",
                "organization.edit.image" : "Cette image est affichée sur la page d’accueil de votre organisation. Format recommandé : 1000 pixels de largeur sur 400 pixels de hauteur.",

            }
        },
        en: {
            translations: {
            }
        }
    },
    fallbackLng: "fr",
    debug: true,

    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
