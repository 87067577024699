import React, { useContext } from 'react';
import style, { ThemeContext } from 'styled-components';
import { Icon, IconType, IconWeight } from '../Icon';

interface ActionButtonProps {
    icon: IconType,
    number: string,
}

const ActionButton: React.FC<ActionButtonProps> = ({ icon, number }) => {
    const themeContext = useContext(ThemeContext);
    return (
        <Container>
            <IconStyle>
                <Icon iconType={icon} color={themeContext.onVariant} size={18} iconWeight={IconWeight.bold} />
            </IconStyle>
            <Label>{number}</Label>
        </Container>
    )
}

export default ActionButton;

const Container = style.span`
  display: flex;
  align-items: center;
`

const IconStyle = style.span`
  display: inline-block;
  display: inline-flex;
  color: ${props => props.theme.onVariant};
  margin-right: 8px;
`;

const Label = style.span`
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  letter-spacing: 0.298182px;
  color: ${props => props.theme.onVariant};
`;