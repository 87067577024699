import { createDocument, createDocumentWithId, decrementValueInDocument, deleteDocument, getDocument, incrementValueInDocument, listenToCollectionQuery, updateDocument } from "../database/databaseRepository";
import { mapDataToIdea } from "../mapping/IdeaMapper";
import { Box, Idea, User } from "../types/type";

export const listenToIdeasList = (
    boxId: string,
    userId: string,
    updateOrganizationsList: (documents: Array<Idea>) => void
) => {
    listenToCollectionQuery(
        '/ideas',
        { leftOperand: 'box.id', operator: "equals", rightOperand: boxId },
        ideas => updateOrganizationsList(ideas.map(mapDataToIdea).filter(i => i.status >= 100))
    );
};

export const isIdeaLiked = async (ideaId: string, userId: string): Promise<boolean> =>
    (await getDocument(`ideas/${ideaId}/likers`, userId)) ? true : false

export const likeIdea = async (
    ideaId: string,
    userId: string,
    userFirstName: string,
    userLastName: string,
    boxId: string
): Promise<any> => {
    await createDocumentWithId(`ideas/${ideaId}/likers`, userId, {
        userFirstName,
        userLastName,
        creationDate: Date.now()
    })
    await incrementValueInDocument(`boxes/${boxId}`, 'statistics.nbOfLikes')
    return await incrementValueInDocument(`ideas/${ideaId}`, 'statistics.nbOfLikes')
}

export const viewIdea = (ideaId: string) => incrementValueInDocument(`ideas/${ideaId}`, 'statistics.nbOfViews');

export const unlikeIdea = async (ideaId: string, userId: string, boxId: string): Promise<any> => {
    await deleteDocument(`ideas/${ideaId}/likers/${userId}`)
    await decrementValueInDocument(`boxes/${boxId}`, 'statistics.nbOfLikes')
    return await decrementValueInDocument(`ideas/${ideaId}`, 'statistics.nbOfLikes')
}

export const createIdea = async (title: string, description: string, user: User, organizationName: string, organizationId: string, box: Box): Promise<string> => {
    const date = Date.now();
    const obj = await createDocument('/ideas', {
        id: "todo",
        organization: {
            id: organizationId,
            name: organizationName
        },
        title: title,
        body: description,
        author: {
            firstName: user.firstName,
            lastName: user.lastName,
            id: user.id
        },
        statistics: {
            nbOfComments: 0,
            nbOfLikes: 0,
            nbOfViews: 0
        },
        box: {
            id: box.id,
            title: box.name
        },
        creationDate: date,
        lastUpdateDate: date,
        status: 100
    })
    await updateDocument(`ideas/${obj.id}`, { id: obj.id });
    await incrementValueInDocument(`boxes/${box.id}`, 'statistics.nbOfIdeas')
    return obj.id;
}

export const getIdea = async (ideaId: string) => {
    const doc = await getDocument('/ideas', ideaId);
    if (doc == null) {
        throw new Error('idea not found');
    }
    return mapDataToIdea(doc);
}

export const archiveIdea = async (ideaId: string, boxId: string) => {
    await updateDocument(`/ideas/${ideaId}`, { status: 3 })
    await decrementValueInDocument(`boxes/${boxId}`, 'statistics.nbOfIdeas')
};