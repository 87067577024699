import React, { useContext, useEffect, useState } from 'react';
import Container from "./Container";
import style from "styled-components";
import { Icon, IconType } from '../Icon';
import { ThemeContext } from 'styled-components';
import { Idea, User } from '../../utils/types/type';
import moment from 'moment';
import Tag from '../Tag';
import { isIdeaLiked, likeIdea, unlikeIdea } from '../../utils/repositories/IdeaRepository';
import { addToQueue } from '../Toast/utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface IdeaProps {
    idea: Idea;
    user?: User;
}

export const ListIdeaItem: React.FC<IdeaProps> = ({ idea, user }): JSX.Element => {
    const themeContext = useContext(ThemeContext);
    const [liked, setLiked] = useState<boolean>(false);
    const { t } = useTranslation();

    useEffect(() => {
        user && isIdeaLiked(idea.id, user.id).then(res => setLiked(res))
    }, [idea.id, user])

    const handleLike = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        if (liked) {
            user && unlikeIdea(idea.id, user.id, idea.box.id).then(() =>
                isIdeaLiked(idea.id, user.id).then(res => {
                    setLiked(res)
                    addToQueue(t('idea.like.action.liked'))
                }))
        } else {
            user && likeIdea(idea.id, user.id, user.firstName, user.lastName || '', idea.box.id).then(() => isIdeaLiked(idea.id, user.id).then(res => {
                addToQueue(t('idea.like.action.unliked'))
                setLiked(res)
            }));
        }
    }

    return (
        <Container>
            <ContentWrapper>
                <CustomLink to={`/i/${idea.id}`}>
                    <Title>{idea.title}</Title>
                    <Description>{idea.body}</Description>
                    <Actions>
                        <Heart onClick={handleLike}>
                            {liked ? (<>
                                <Icon iconType={IconType['heart-fill']} color={themeContext.error} size={20} />
                                <HeartTextRed>{idea.statistics.nbOfLikes}</HeartTextRed>
                            </>) : (<>
                                <Icon iconType={IconType['heart-fill']} color={themeContext.primary} size={20} />
                                <HeartText>{idea.statistics.nbOfLikes}</HeartText>
                            </>)}
                        </Heart>
                        <Metadata>
                            <Item>
                                <Icon iconType={IconType.comment} color={themeContext.onBackground} size={16} />
                                <MetaText>{idea.statistics.nbOfComments}</MetaText>
                            </Item>
                            <Item>
                                <Icon iconType={IconType.person} color={themeContext.onBackground} size={16} />
                                <MetaText>{idea.author.firstName}</MetaText>
                            </Item>
                            <Item>
                                <Icon iconType={IconType.calendar} color={themeContext.onBackground} size={16} />
                                <MetaText>{moment(idea.creationDate).fromNow()}</MetaText>
                            </Item>
                            {idea.ideaStatus && (
                                <Item>
                                    <Tag label={idea.ideaStatus?.label || ""} description={idea.ideaStatus?.description || ""} />
                                </Item>
                            )}
                        </Metadata>
                    </Actions>
                </CustomLink>
            </ContentWrapper >
        </Container >
    );
};

const ContentWrapper = style.div`
    padding: 24px 36px;
    cursor: pointer;
    margin-bottom: 12px;
    background-color: ${props => props.theme.surface};
    border-radius: 8px;
    transition: box-shadow 0.2s;
    &:hover {
        box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.10);
    }
`;

const Title = style.h1`
    ${props => props.theme.paragraphTitle.desktop};
    margin: 0 0 8px 0;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const Description = style.h3`
    ${props => props.theme.information.desktop};
    margin: 0 0 16px 0;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: ${p => p.theme.onBackground};
`;

const Actions = style.div`
    display: flex;
    justify-content: space-between;
`;

const Metadata = style.div`
    display: flex;
    
`;

const MetaText = style.p`
    color: ${p => p.theme.onBackground};
    margin: 0 0 0 8px;
    top: -2px;
    position: relative;
    font-weight: 500;
`;

const HeartText = style.p`
    color: ${p => p.theme.primary};
    margin: 0 0 0 10px;
    position: relative;
    font-weight: 700;
    font-size: 17px;
`;

const HeartTextRed = style.p`
    color: ${p => p.theme.error};
    margin: 0 0 0 10px;
    position: relative;
    font-weight: 700;
    font-size: 17px;
`;

const Item = style.div`
    display: flex;
    flex-direction: row;
    margin-left: 16px;
`;

const Heart = style.a`
    display: flex;
    
`;

const CustomLink = style(Link)`
    text-decoration: none;
`;