import React from 'react';
import style from 'styled-components';
import { useTranslation } from 'react-i18next';

import SectionTitle from '../../../components/SectionTitle';
import subPagesGen from '../SubPages';
import {
    useHistory,
    useLocation,
    useRouteMatch
} from "react-router-dom";
import Button, { IButtonStyle, IWidth } from '../../../components/Button';
import { IconType } from '../../../components/Icon';

import CreateOrganizationModal from './components/CreateBoxModal';
import { requestModalOpening } from '../../../components/Modal/utils';
import { createDocument, updateDocument } from '../../../utils/database/databaseRepository';
import { Organization } from '../../../utils/types/type';

const Menu: (props: { organization: Organization }) => JSX.Element = ({ organization }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { pathname } = useLocation();
    const { url } = useRouteMatch();

    return (
        <div>
            <SectionTitle>{t('menu.organization.title')}</SectionTitle>
            <Container>
                {subPagesGen(url).map(r => {
                    const Icon = Icons[r.key];
                    return (
                        <Item
                            key={r.key}
                            selected={pathname === r.path}
                            onClick={() => history.push(r.path)}
                        >
                            <Icon />
                            {t(`menu.organization.${r.key.split('-').pop()}`)}
                        </Item>
                    )
                })}
                <Button
                    iconRight={IconType.plus} width={IWidth['match-parent']}
                    colorStyle={IButtonStyle.positive}
                    label={t('menu.organization.create')}
                    handleClick={() => requestModalOpening(CreateOrganizationModal, {
                        onValidate: async (value: string, resume: string) => {
                            try {
                                const obj = await createDocument('/boxes', {
                                    name: value,
                                    creationDate: new Date(),
                                    lastUpdateDate: new Date(),
                                    description: resume,
                                    summary: resume,
                                    status: 100,
                                    organization: {
                                        id: organization.id,
                                        name: organization.name,
                                    },
                                    statistics: {
                                        nbOfComments: 0,
                                        nbOfLikes: 0,
                                        nbOfIdeas: 0,
                                    },
                                });
                                await updateDocument(`/boxes/${obj.id}`, { id: obj.id });
                                history.push(`/b/${obj.id}`);
                                return { done: true };
                            } catch (error) {
                                return { error }
                            }
                        },
                    })}
                ></Button>
            </Container>
        </div>
    );
};

const Container = style.div`
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 10px 18px;
  padding-bottom: 18px;
  width: fit-content;
`;

const Item = style.div<{ selected: boolean }>`
  margin: 8px 0;
  width: 215px;
  color: ${props => props.theme.onBackground};
  ${props => props.theme.noSelect};
  ${props => props.theme.transition};
  ${props => props.theme.body.desktop};
  ${props => props.theme.action};
  font-size: 17px;
  padding: 10px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  & > i, & > img {
    font-size: 22px;
    margin-right: 10px;
    width: 32px;
  }
  & > img {
    height: 22px;
    margin-right: 20px;
    width: 22px;
  }
  ${({ selected, theme }) => selected ? `
  background-color: ${theme.primaryLight};
  color: ${theme.primary};
  ` : `
  &:hover {
    background-color: ${theme.backgroundMedium};
    cursor: pointer;
  }
  `
    } 
`;

export default Menu;

/*
Accueil : fa-home
Mon compte : fa-user-circle
Statistiques : fa-chart-pie
Organisations : fa-user-friends
*/

const Icons: {
    'organization-home': React.FC,
    'organization-edit': React.FC,
    'organization-stats': React.FC,
    'organization-voice': React.FC,
} = {
    'organization-home': () => <i className="fa fa-home" />,
    'organization-edit': () => <i className="fa fa-edit" />,
    'organization-stats': () => <i className="fa fa-chart-pie" />,
    'organization-voice': () => <i className="fa fa-bullhorn" />,
}