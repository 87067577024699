import Style from 'styled-components';

export const Container = Style.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: ${props => props.theme.surface};
`;

export const LeftDiv = Style.div`
  height: 100%;
  width: 41.2%;
  display: flex;
  flex-direction: column;
  background-image: url(/assets/bulb.jpg);
  background-size: cover;
  color: white;
  & > div:first-child {
    padding: 2rem;
  }
  & > div:not(:first-child) {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    & > div {
      margin: ${props => props.theme.large.desktop};
    }
    & > div:first-child {
      text-shadow: 0 .2rem 1.7rem rgba(0, 0, 0, 0.93);
      ${props => props.theme.extraLarge.desktop}
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      color: #ffffff;
    }
    & > div:not(:first-child) {
      text-shadow: 0 .2rem 1.7rem rgba(0, 0, 0, 0.93);
      ${props => props.theme.heading.desktop}
      font-weigh: 400 !important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      color: #ffffff;
    }
  }
`;

export const RightDiv = Style.div`
  height: 100%;
  width: 59.8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > div {
    width: 60%;
  }
`;

export const Logo = Style.img`
  width: 100px;
  margin: ${props => props.theme.large.desktop}
`;

export const Title = Style.div`
  ${props=> props.theme.extraLarge.desktop}
  text-align: center;
  color: ${props=> props.theme.primary};
  text-align: center;
  margin: 2.2rem;
`;

export const Desc = Style.div`
  ${props=>props.theme.body.desktop}
  color: var(--black);
  text-align: center;
`;

export const Legal = Style.div`
  ${props=>props.theme.information.desktop};
  color: ${props=>props.theme.onBackground};
  text-align: center;
  padding-top:0 !important;
`;

export const Form = Style.form`
  width: 60%;
  min-width: 480px;
  padding: 40px;
  overflow:auto;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  & > div:not(:last-child) {
    margin-bottom: 12px;
    margin-top: 12px;
  }
  & > div:last-child {
    width: 100%;
    padding-top: 18px;

    & > div {
      width: 100%;
      box-sizing: border-box;
      background-color: var(--black);
      border-radius: 6px;
      height: 48px;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.34px;
      text-align: center;
      color: #ffffff;
      border: none;
    }
  }
`;

export const UserSection = Style.div`
  display: flex;
  justify-content: space-between;
  & > div {
    flex-grow: 1;
  }
  & > div:first-child {
    padding-right: 10px;
  }
  & > div:last-child {
    padding-left: 10px;
  }
`;