import React from "react";
import { useTranslation } from "react-i18next";
import style, { withTheme } from 'styled-components';
import SectionTitle from "../../../../../components/SectionTitle";
import Button, { IButtonStyle } from "../../../../../components/Button";
import Modal from './Modal';
import { requestModalOpening } from '../../../../../components/Modal/utils';

const Delete: React.FC<{ theme: { error: string } }> = ({ theme }) => {
    const { t } = useTranslation();
    return (
        <>
            <SectionTitle>{t('account.profile.deletion.title')}</SectionTitle>
            <Container>
                <DeletionSection>
                    {t('account.profile.session.deletion')}
                    <Button
                        label={t('account.profile.deletion.body')}
                        colorStyle={IButtonStyle.negative}
                        handleClick={() => requestModalOpening(Modal)} />
                </DeletionSection>
            </Container>
        </>
    );
}

const Container = style.div`
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 30px 36px;
  & > form > button {
    margin-top: 30px;
`;

const DeletionSection = style.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ${props => props.theme.body.desktop}
`;

export default withTheme(Delete);