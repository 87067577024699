import { firestore } from "firebase";
import { User } from "../types/type";

export const mapDataToUser = (obj: any): User => {
    if (obj) {
        const res: User = {
            creationDate: new Date((obj.creationDate as firestore.Timestamp).valueOf()),
            email: obj.email,
            firstName: obj.firstName,
            id: obj.id,
            lastName: obj.lastName,
            lastUpdateDate: new Date((obj.lastUpdateDate as firestore.Timestamp).valueOf()),
            status: obj.status
        }
        return res;
    } else {
        throw new Error("impossible to map data to User: " + obj);
    }
}