import * as React from "react";
import Style from "styled-components";
import { ThemeContext } from "styled-components";
import { Icon, IconType } from "../Icon";

export enum IButtonStyle {
    "action",
    "negative",
    "negative-active",
    "positive",
    "dark",
    "apple",
    "primary"
}

export enum IWidth {
    "fit-content",
    "match-parent"
}

export enum IMargin {
    "none",
    "regular",
    "medium",
    "big",
}

export enum IButtonAlign {
    "center"
}

export interface IButtonProps {
    label?: string;
    colorStyle?: IButtonStyle;
    handleClick?: (event: React.MouseEvent<HTMLElement>) => void;
    handleHover?: (isHover: boolean) => void;
    iconRight?: IconType;
    iconLeft?: IconType;
    width?: IWidth;
    margin?: IMargin;
    loading?: boolean;
    disabled?: boolean;
    align?: IButtonAlign;
}

const Button = ({
    label = "button",
    colorStyle,
    handleClick = () => { },
    handleHover = () => { },
    iconRight,
    disabled,
    iconLeft,
    width = IWidth["fit-content"],
    margin = IMargin.none,
    loading = false,
    align
}: IButtonProps) => {
    const theme = React.useContext(ThemeContext);
    const [hover, setHover] = React.useState<boolean>(false);

    React.useEffect(() => handleHover(hover), [handleHover, hover]);

    const marginSize = (): string => {
        switch (margin) {
            case IMargin.regular:
                return `${theme.regular.desktop} 0px`;
            case IMargin.medium:
                return `${theme.medium.desktop} 0px`;
            case IMargin.big:
                return `${theme.large.desktop} 0px`;
            default:
                return '0px 0px';
        }
    };

    const styleProps: CSSButtonProps = {
        theme: { ...theme },
        backgroundColor: theme.backgroundMedium,
        textColor: theme.onBackground,
        backgroundColorHover: theme.backgroundBold,
        backgroundColorClick: theme.backgroundMedium,
        textColorHover: theme.onBackground,
        font: theme.action.desktop,
        maxSize: width === IWidth["match-parent"],
        margin: marginSize(),
        align: align
    };

    const style = (): CSSButtonProps => {
        switch (colorStyle) {
            case IButtonStyle.action:
                styleProps.backgroundColor = theme.backgroundMedium;
                styleProps.textColor = theme.onBackground;
                styleProps.backgroundColorHover = theme.onBackground;
                styleProps.textColorHover = theme.surface;
                break;
            case IButtonStyle.dark:
                styleProps.backgroundColor = theme.onSurfacePure;
                styleProps.textColor = theme.surface;
                styleProps.backgroundColorHover = theme.onSurface;
                styleProps.textColorHover = theme.surface;
                break;
            case IButtonStyle.negative:
                styleProps.backgroundColor = theme.errorLight;
                styleProps.textColor = theme.error;
                styleProps.backgroundColorHover = theme.error;
                styleProps.textColorHover = theme.surface;
                break;
            case IButtonStyle["negative-active"]:
                styleProps.backgroundColor = theme.error;
                styleProps.textColor = theme.surface;
                styleProps.backgroundColorHover = theme.errorLight;
                styleProps.textColorHover = theme.error;
                break;
            case IButtonStyle.positive:
                styleProps.backgroundColor = theme.successLight;
                styleProps.textColor = theme.success;
                styleProps.backgroundColorHover = theme.success;
                styleProps.textColorHover = theme.surface;
                break;
            case IButtonStyle.primary:
                styleProps.backgroundColor = theme.primaryLight;
                styleProps.textColor = theme.primary;
                styleProps.backgroundColorHover = theme.primary;
                styleProps.textColorHover = theme.surface;
                break;
            // TODO : apple button
            default:
                break;
        }
        return styleProps;
    };

    return (
        <>
            {loading ? <Spinner className="spinner" /> : (
                <ButtonContainer disabled={disabled} {...style()} onClick={handleClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                    <FlexContainer>
                        {!loading && iconRight && <IconSpanRight><Icon iconType={iconRight} color={hover ? styleProps.textColorHover : styleProps.textColor} /></IconSpanRight>}
                        {!loading && label}
                        {!loading && iconLeft && <IconSpan><Icon iconType={iconLeft} color={hover ? styleProps.textColorHover : styleProps.textColor} /></IconSpan>}
                    </FlexContainer>
                </ButtonContainer>
            )}
        </>
    );
};

export default Button;

interface CSSButtonProps {
    backgroundColor?: string;
    textColor?: string;
    backgroundColorHover?: string;
    backgroundColorClick?: string;
    textColorHover?: string;
    font?: string;
    theme?: object;
    maxSize?: boolean;
    margin?: string;
    align?: IButtonAlign;
}

const ButtonContainer = Style.button<CSSButtonProps>`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 44px;
    border-radius: 4px;
    padding: 0 24px;
    ${props => props.font};
    border: none;
    transition: 0.1s;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.textColor};
    ${props => props.theme.noOutline};
    ${props => props.theme.noSelect};
    ${props => props.maxSize && 'width: -webkit-fill-available;'}
    margin: ${props => props.margin};
    ${props => props.align === IButtonAlign.center && 'margin: 0 auto;'}

    &:disabled:hover {
        cursor: not-allowed;
    }

    &:not([disabled]):hover {
        background-color: ${props => props.backgroundColorHover};
        color: ${props => props.textColorHover};
        cursor: pointer;
    }

    &:active {
        border:none;
        background-color: ${props => props.backgroundColorClick};
        cursor: pointer;
    }

    &:focus {
        border: none;
    }
`;

const IconSpan = Style.span`
    top: 2px;
    position: relative;
    margin-left: 8px;
`;

const IconSpanRight = Style.span`
    position: absolute;
    left: 16px;
    margin-top: 2px;
`;

const FlexContainer = Style.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: -webkit-fill-available;
`;

const Spinner = Style.span`
    height: 25px;
    width: 25px;
`