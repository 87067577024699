import React, {useContext, useState} from 'react';
import { useHistory, Link } from 'react-router-dom';
import * as Style from './style';
import LogoBrusketa from '../../assets/logo_large_white.svg';
import Input from '../../components/TextField';
import { signIn } from '../../utils/authentication/useAuth';
import { useTranslation } from 'react-i18next';
import Button, { IButtonStyle, IMargin, IWidth } from '../../components/Button';
import {UserContext} from "../../utils/contexts/UserContext";

const Signin: React.FC = (): JSX.Element => {
    const {reloadUserInfo} = useContext(UserContext);
    const history = useHistory();
    const { t } = useTranslation();

    const [email, updateEmail] = useState("");
    const [password, updatePassword] = useState("");
    const [errorMail, updateErrorMail] = useState(null);
    const [errorPassword, updateErrorPassword] = useState(null);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        signIn(email, password).then(() => {
            reloadUserInfo();
            history.push('/');
        }).catch(error => {
            switch (error.code) {
                case 'auth/invalid-email': updateErrorMail(t(error.code));
                    break;
                case 'auth/user-disabled': updateErrorMail(t(error.code));
                    break;
                case 'auth/user-not-found': updateErrorMail(t(error.code));
                    break;
                case 'auth/wrong-password': updateErrorPassword(t(error.code));
                    break;
                case 'auth/network-request-failed':
                    updateErrorPassword(t(error.code));
                    updateErrorMail(t(error.code));
                    break;
                default:
                    console.error(error.code);
                    updateErrorMail(t('auth/common-error'));
                    updateErrorPassword(t('auth/common-error'));
            }
        });
        return false;
    };
    const resetErrors = () => {
        updateErrorMail(null);
        updateErrorPassword(null);
    };
    return (
        <Style.Container>
            <Style.LeftDiv>
                <Link to="/"><Style.Logo src={LogoBrusketa} alt={"Brusketa logo"} /></Link>
                <div>
                    <div>{t('signin.title')}</div>
                    <div>{t('signin.catchphrase')}</div>
                </div>
            </Style.LeftDiv>
            <Style.RightDiv>
                <div>
                    <Style.Title>{t('signin.form.title')}</Style.Title>
                    <Style.Desc>{t('signin.form.description')}</Style.Desc>
                    <Style.Desc>{t('signin.form.signup')}<Link to="/signup">{t('signin.form.signup.link')}</Link></Style.Desc>
                </div>
                <Style.Form onSubmit={handleSubmit}>
                    <Input hint={t('general.hint.email')} onChange={event => { updateEmail(event.target.value); resetErrors(); }} value={email} error={errorMail} autofocus={true} />
                    <Input hint={t('general.hint.password')} onChange={event => { updatePassword(event.target.value); resetErrors(); }} value={password} type="password" error={errorPassword} />
                    <Button label={t('signin.form.connect')} width={IWidth["match-parent"]} colorStyle={IButtonStyle.dark} margin={IMargin.medium} />
                </Style.Form>
            </Style.RightDiv>
        </Style.Container>
    );
};

export default Signin;