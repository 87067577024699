import React from 'react';
import {
    Transition,
    TransitionGroup,
} from 'react-transition-group';
import { useToast } from './utils';
import { duration, transitionStyles } from './constants';
import ToastUnit from './unit';
import { TransitionStyle } from './type';

const ToastContainer = () => {
    const queue = useToast();
    return (
        <TransitionGroup style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {queue.map(q => (
                <Transition timeout={duration} key={q.id}>
                    {(state: TransitionStyle) => (
                        <ToastUnit
                            style={transitionStyles[state]}
                            text={q.text}
                            toastStyle={q.style}
                        />
                    )}
                </Transition>
            ))}
        </TransitionGroup>
    );
}

export default ToastContainer;