import React from 'react';
import "./style.css";
import Loading from './Loading';

const SplashScreen =  () => {
  return <div className="splash-screen">
      <div className="screen-container">
          <div className="centered-container">
            <Loading/>
          </div>
      </div>
  </div>;
}

export default SplashScreen;