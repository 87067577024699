import * as React from 'react';
import style from 'styled-components';

interface IconProps {
    iconCode: string;
    color?: string;
    fontSize?: string;
}

const IconDeprecated = ({ iconCode, fontSize = "22px", color }: IconProps) => {
    return <IconContainer style={{ color, fontSize }} className={iconCode} />;
};

export default IconDeprecated;

const IconContainer = style.i`
    font-weight: 600;
`;