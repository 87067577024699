import { createDocument, getDocument, listenToCollectionQuery, updateDocument } from '../database/databaseRepository';
import { mapDataToOrganization } from '../mapping/OrganizationMapper';
import { Organization, User } from '../types/type';

export const listenToUserOrganizations = (userId: string, updateOrganizationsList: (documents: Array<Organization>) => void) => {
    const res: Array<Organization> = [];
    listenToCollectionQuery(
        '/organizations',
        { leftOperand: "administratorsId", operator: "array-contains", rightOperand: userId },
        (org) => {
            org.forEach(organization => res.push(mapDataToOrganization(organization)))
            updateOrganizationsList(res);
        }
    );
};

export const getOrganization = async (organizationId: string) => {
    const doc = await getDocument('/organizations', organizationId);
    if (doc === null) {
        throw new Error('organization not found');
    }
    return mapDataToOrganization(doc);
};

export const isMemberOfOrganization = (organization: Organization, user: User) => {
    return organization.administrators.filter(a => a.id === user.id).length > 0;
};

export const createOrganization = async (user: User, name: string, summary: string): Promise<string> => {
    const date = Date.now();
    const obj = await createDocument('/organizations', {
        id: "todo",
        name,
        summary,
        creationDate: date,
        lastUpdateDate: date,
        administrators: [{ id: user.id, lastName: user.lastName, firstName: user.firstName }],
        administratorsId: [user.id],
        status: 100
    });
    await updateDocument(`organizations/${obj.id}`, { id: obj.id });
    return obj.id;
};

export const updateOrganizationGeneral = async (id: string, name: string, summary: string, description: string): Promise<void> => {
    return await updateDocument(`organizations/${id}`, {
        name,
        summary,
        description,
        lastUpdateDate: new Date()
    });
};

export const updateOrganizationContact = async (id: string, contactEmail: string, contactPhone: string, contactWebsite: string): Promise<void> => {
    return await updateDocument(`organizations/${id}`, {
        contactEmail,
        contactPhone,
        contactWebsite,
        lastUpdateDate: new Date()
    });
};

export const updateImages = async (id: string, coverPath: string, logoPath: string): Promise<void> => {
    return await updateDocument(`organizations/${id}`, {
        coverPath,
        logoPath,
        lastUpdateDate: new Date()
    });
};

export const archiveOrganization = async (organizationId: string) => {
    await updateDocument(`organizations/${organizationId}`, { status: 3 })
};