import React from 'react';
import style from 'styled-components';
import Icon from '../IconDeprecated';
import ContainerBase from './Container';

interface IInformationProps {
    title: string,
    onClick?: () => void,
}

const Information: React.FC<IInformationProps> = ({title, children, onClick}): JSX.Element => {
    return (
        <ContainerBase>
            <Container onClick={onClick}>
                <Title>{title} <Icon iconCode="fas fa-arrow-right"/></Title>
                <Desc>{children}</Desc>
            </Container>
        </ContainerBase>
    );
}

export default Information;

const Container = style.div`
  padding: 24px 36px;
  cursor: pointer;
  margin-bottom: 24px;
  background-color: ${props => props.theme.surface};
  border-radius: 6px; 
`;

const Title = style.div`
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  /* identical to box height */

  letter-spacing: 0.2px;
  margin-bottom: 13px;
`;

const Desc = style.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: 0.2px;

  /* Secondary Deep */

  color: #767676;
`;