import React, { useContext, useEffect, useState } from 'react';
import Style from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BoxGrid from '../../../components/BoxGrid';
import SearchBoxGrid from '../../../components/BoxGrid/SearchBoxGrid';
import { IconType } from '../../../components/Icon';
import SectionTitle from '../../../components/SectionTitle';
import TextField from '../../../components/TextField';
import { getBoxesFromOrganization } from '../../../utils/repositories/boxRepository';
import { getOrganization } from '../../../utils/repositories/organizationsRepository';
import { Box, Organization } from '../../../utils/types/type';
import OrganizationCard from './components/OrganizationCard';
import { NavigationContext } from '../../../utils/contexts/NavigationContext';

const Overview = () => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const [organization, updateOrganization] = useState<Organization | null>(null);
    const [searchString, updateSearchString] = useState<string>('');
    const [boxes, updateBoxes] = useState<Box[] | null>(null);
    const { setNavBarTitle } = useContext(NavigationContext);

    useEffect(() => {
        (async () => {
            const organization = await getOrganization(id);
            const boxes = await getBoxesFromOrganization(id);
            setNavBarTitle(organization.name);
            updateOrganization(organization);
            updateBoxes(boxes);
        })();
    }, [id]);
    return (
        <div>
            {organization ? (
                <div>
                    <OrganizationCard organization={organization} />
                    <SectionTitle>{t('organization.overview.box')}</SectionTitle>
                    <TextField placeholder={t('home.search.placeholder')}
                        onChange={event => updateSearchString(event.target.value)} value={searchString} error=''
                        icon={IconType.search} autofocus={true} />
                    {searchString.length > 0 ? (
                        <SearchBoxGrid boxes={boxes || []} searchString={searchString} />
                    ) : (
                            <BoxesContainer>
                                <BoxGrid boxes={boxes || []} />
                            </BoxesContainer>
                        )}
                </div>
            ) : (
                    <div>Not found</div>
                )}
        </div>
    );
}

const BoxesContainer = Style.div`
  margin-top: 18px;
`;

export default Overview;