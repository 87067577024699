import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Subject } from 'rxjs';
import { Toast, ToastStyle } from './type';

const toastQueue: Toast[] = [];
const toastQueueSubject = new Subject<Toast[]>();

export const addToQueue: (text: string, style?: ToastStyle) => void = (text, style) => {
    toastQueue.push({ text, id: uuid(), style });
    toastQueueSubject.next([...toastQueue]);
};

export const popFromQueue: () => void = () => {
    toastQueue.shift();
    toastQueueSubject.next([...toastQueue]);
};

export const useToast = () => {
    const [queue, updateQueue] = useState<Toast[]>([]);
    useEffect(() => {
        toastQueueSubject.subscribe({
            next: (v) => updateQueue(v),
        });
        return toastQueueSubject.unsubscribe;
    }, []);
    return queue;
};