import { Route } from '../../../utils/routes/routes';
import React from 'react';
import Profile from "./Profile";
import Organizations from './Organizations';

type Keys = 'account-home' | 'account-details' | 'account-stats' | 'account-organization' | 'account-about';
const base: Route<Keys>[] = [
    {
        key: 'account-home',
        path: '',
        // title: 'Home',
        Component: () => <div>Home</div>,
        exact: true,
    },
    {
        key: 'account-details',
        path: '/account',
        // title: 'Home',
        Component: () => <Profile />,
    },
    {
        key: 'account-stats',
        path: '/stats',
        // title: 'Home',
        Component: () => <div>Stats</div>,
    },
    {
        key: 'account-organization',
        path: '/organizations',
        // title: 'Home',
        Component: () => <Organizations />
    },
    {
        key: 'account-about',
        path: '/about',
        // title: 'Home',
        Component: () => <div>About</div>,
    },
];

export default (path: string): Route<Keys>[] => base.map(b => ({ ...b, path: `${path}${b.path}` }));