import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Style from 'styled-components';
import { Modal } from '../../../../components/Modal/type';
import { requestModalClosing } from '../../../../components/Modal/utils';
import TextField from '../../../../components/TextField';
import Button, { IButtonStyle } from '../../../../components/Button';

const CreateBoxModal: Modal = ({ clickedOutside, additionalProps: { onValidate } }) => {
    const { t } = useTranslation();
    const [value, updateValue] = useState<string>('');
    const [resume, updateResume] = useState<string>('');
    const [loading, updateLoading] = useState<boolean>(false);
    const [error, updateError] = useState<string | null>(null);
    if (clickedOutside && !loading)
        requestModalClosing();
    return (
        <Container>
            <Title>{t('box.create.title')}</Title>
            <Desc>{t('box.create.desc')}</Desc>
            <TextField
                hint={t('box.create.field')}
                onChange={(e) => !loading && updateValue(e.target.value) && updateError(null)}
                value={value}
                marginBottom={24}
                error={error}
            ></TextField>
            <TextField
                hint={t('box.create.resume')}
                onChange={(e) => !loading && updateResume(e.target.value)}
                value={resume}
                marginBottom={36}
                maxLength={400}
                maxLines={5}
            />
            <Buttons>
                <Button
                    label={t('box.create.cancel')}
                    handleClick={requestModalClosing}
                    loading={loading}
                ></Button>
                <Button
                    label={t('box.create.validate')}
                    colorStyle={IButtonStyle.positive} handleClick={() => {
                        updateLoading(true);
                        onValidate(value, resume).then((res: { done?: boolean, error?: string }) => {
                            console.log(res);
                            if (res.done)
                                requestModalClosing();
                            else {
                                updateLoading(false);
                                console.log(error);
                                updateError(error);
                            }
                        });
                    }}
                    loading={loading}
                ></Button>
            </Buttons>
        </Container>
    );
}

const Container = Style.div`
  padding: ${({ theme }) => theme.large.desktop};
  box-shadow: 0px 1px 81px rgba(0, 0, 0, 0.32);
  border-radius: 8px;  
  max-width: 40vw;
`;

const Title = Style.div`
  ${({ theme }) => theme.heading.desktop}
  margin-bottom: ${({ theme }) => theme.large.desktop};
`;
const Desc = Style.div`
  margin-bottom: ${({ theme }) => theme.large.desktop};
  color: ${({ theme }) => theme.onBackground};
  ${({ theme }) => theme.information.desktop}
`;

const Buttons = Style.div`
  display: flex;
  justify-content: space-between;
`;

export default CreateBoxModal;