import { getDocument, listDocuments, listDocumentsQuery } from '../database/databaseRepository';
import { mapDataToBox } from '../mapping/BoxMapper';
import { Box } from '../types/type';

export const listAllBoxes = async () => {
    const docs = await listDocuments('/boxes');
    if (docs == null) {
        throw new Error('box list not found');
    }
    const res: Array<Box> = [];
    docs.forEach(b => res.push(mapDataToBox(b)));
    return res;
}

export const getBox = async (boxId: string) => {
    const doc = await getDocument('/boxes', boxId);
    if (doc == null) {
        throw new Error('box not found');
    }
    return mapDataToBox(doc);
}

export const getBoxesFromOrganization = async (organizationId: string) => {
    const docs = await listDocumentsQuery('/boxes', {
        leftOperand: 'organization.id',
        operator: 'equals',
        rightOperand: organizationId,
    });
    if (docs == null)
        throw new Error('No matching boxes');
    const res: Array<Box> = [];
    docs.forEach(b => res.push(mapDataToBox(b)));
    return res;
}