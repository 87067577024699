import React from 'react';
import Style, { ThemeContext } from 'styled-components';
import Chevron from './Chevron';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

interface LabelLink {
    text: string,
    link?: string,
}

interface BreadcrumbProps {
    labels: Array<LabelLink>,
    loading: boolean,
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({labels, loading, children}): JSX.Element => {
    const theme = React.useContext(ThemeContext);

    if (loading) {
        return (
            <Trail>
                <SkeletonTheme color={theme.backgroundBold} highlightColor={theme.backgroundMedium}>
                    {labels.map((label, index) => 
                        <React.Fragment key={`bread---${index}`}>
                            <Skeleton style={{width: "120px"}} />
                            {(label.link !== undefined && label.link !== "") && <Chevron/>}
                        </React.Fragment>
                    )}
                </SkeletonTheme>
            </Trail>
        )
    } else {
        return (
            <Trail>
                <SkeletonTheme color={theme.backgroundBold} highlightColor={theme.backgroundMedium}>
                {labels.map((label, index) => {
                    if (label.link !== undefined && label.link !== "") {
                        return (
                            <React.Fragment key={`bread---${index}`}>
                                <CartoucheColor href={label.link}>{label.text}</CartoucheColor>
                                <Chevron/>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment key={`bread---${index}`}>
                                <CartoucheNoColor>{label.text}</CartoucheNoColor>
                            </React.Fragment>
                        )
                    }
                })}
                </SkeletonTheme>
            </Trail>
        )
    }
};

export default Breadcrumb;

const Trail = Style.div`
    font-size:16px;
    font-weight: bold;
    color: ${props => props.theme.onBackground};
    display: flex;
    align-items: center;
`

const Cartouche = Style.a`
    display: inline-block;
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    text-decoration: none;
`

const CartoucheColor = Style(Cartouche)`
    &:hover{
        background-color: ${props => props.theme.backgroundBold};
        border-radius: 6px;
        color: ${props => props.theme.primary};
    }
`

const CartoucheNoColor = Style(Cartouche)`
    &:hover{
        color: ${props => props.theme.onBackground};
    }
`