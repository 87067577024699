import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { Modal, ModalOrNull } from './type';

let modalObject: [ModalOrNull, Object | undefined] = [null, {}];
const modalSubject = new Subject<[ModalOrNull, Object | undefined]>();
modalSubject.next(modalObject);

export const requestModalOpening: (modal: Modal, additionalProps?: Object) => void
    = (modal, additionalProps) => {
        // if (modalObject) {
        //     throw new Error('A modal is already open');
        // }
        modalObject = [modal, additionalProps];
        modalSubject.next(modalObject);
    }

export const requestModalClosing: () => void
    = () => {
        if (!modalObject) {
            throw new Error('A modal isn\'t already open');
        }
        modalObject = [null, undefined];
        modalSubject.next(modalObject);
    }

export const useModal: () => [ModalOrNull, Object]
    = () => {
        const [modal, updateModalState] = useState<[ModalOrNull, Object | undefined]>([null, undefined]);
        useEffect(() => {
            const subscription = modalSubject.subscribe({
                next: (v) => {
                    updateModalState(v);
                },
            });
            return subscription.unsubscribe;
        }, []);
        return [modal[0], modal[1] || {}];
    }