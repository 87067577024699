import React, { useEffect } from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Switch
} from "react-router-dom";
import Routes, { Route as RouteType } from './utils/routes/routes';
import useAuth from './utils/authentication/useAuth';
import ThemeProvider from './utils/theme/theme';
import SplashScreen from './components/SplashScreen';
import Toast from './components/Toast';
import generateRoutes from './utils/routes/generateRoutes';
import UserContextProvider from "./utils/contexts/UserContext";
import Modal from './components/Modal';
import moment from 'moment';
import NavigationContextProvider from './utils/contexts/NavigationContext';
require('moment/locale/fr');

function App() {
    // Passer à false pour ne pas afficher le splashscreen
    const [isLoadingApp, setIsLoadingApp] = React.useState(true);
    const user = useAuth();
    moment.locale('fr');

    useEffect(() => {
        // signIn('a@a.fr', 'aaaaaaaaaaaa');
        setTimeout(() => setIsLoadingApp(false), 500);
        setTimeout(() => {
            // addToQueue(`Bienvenue`);
        }, 1000);
    }, []);

    if (isLoadingApp) {
        return <SplashScreen />
    } else {
        const routes: RouteType[] = user ? Routes.auth : Routes.visitor;
        return (
            <ThemeProvider>
                <Toast />
                <Modal />
                <UserContextProvider>
                    <NavigationContextProvider>
                        <Router>
                            <Switch>
                                {generateRoutes(routes)}
                            </Switch>
                        </Router>
                    </NavigationContextProvider>
                </UserContextProvider>
            </ThemeProvider>
        );
    }
}

export default App;