import React, { useState } from 'react';
import Style from 'styled-components';

import { useModal } from './utils';

const Container: () => JSX.Element = () => {
  const [Modal, additionalProps] = useModal();
  const [ outsideClicked, updateOutsideClicked ] = useState(false);
  if (!Modal && outsideClicked) {
    updateOutsideClicked(false);
  }
  return (
    <>
      {Modal && (
        <ContainerOutline>
          <ContainerBack onClick={() => updateOutsideClicked(true)}/>
          <ModalContainer>
            <Modal clickedOutside={outsideClicked} additionalProps={additionalProps} />
          </ModalContainer>
        </ContainerOutline>
      )}
    </>
  );
}

const ContainerOutline = Style.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
`;

const ContainerBack = Style.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.onSurface}84;
  position: absolute;
`;

const ModalContainer = Style.div`
  background-color: ${({ theme }) => theme.surface};
  box-shadow: 0px 1px 81px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  position: relative;
`;

export default Container;