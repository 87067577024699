import React, { useEffect, useState } from 'react';
import style from 'styled-components';
import {
    Switch,
    useParams,
    useRouteMatch
} from "react-router-dom";

import MainObject from '../../components/MainObject';
import Menu from './Menu';
import subPages from './SubPages';
import generateRoutes from '../../utils/routes/generateRoutes';
import { Organization as Org } from '../../utils/types/type';
import { getOrganization } from '../../utils/repositories/organizationsRepository';

const Organization: () => JSX.Element | null = () => {
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    const [organization, setOrganization] = useState<Org | null>(null);
    const { id } = useParams<{ id: string }>();
    let { path } = useRouteMatch();

    useEffect(() => {
        getOrganization(id).then(o => setOrganization(o)).catch(e => console.error('error while fetching', id, e))
    }, [id]);

    if (!organization)
        return null;

    return (
        <MainObject>
            <CenterContainer>
                <Switch>
                    {generateRoutes(subPages(path, organization || undefined))}
                </Switch>
            </CenterContainer>
            <RightContainer>
                <Menu organization={{ ...organization, id }}/>
            </RightContainer>
        </MainObject>
    );
};
const LeftContainer = style.div`
`;

const CenterContainer = style.div`
`;

const RightContainer = style.div`
`;

export default Organization;