import React from 'react';
import style from 'styled-components';
import {useTranslation} from "react-i18next";
import { Modal as ModalType } from '../../../../../components/Modal/type';
import { requestModalClosing } from '../../../../../components/Modal/utils';

const Modal: ModalType = ({ clickedOutside }) => {
  const {t} = useTranslation();
  if (clickedOutside) {
    requestModalClosing();
  }
  return (
    <Container>
      <Action>
        {t('account.profile.deletion.modal.error')}
  <a href="/login">{t('account.profile.deletion.modal.link.login')}</a>
      </Action>
    </Container>
  );
}

const Container = style.div`
  background: ${({theme}) => theme.surface};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: ${({theme}) => theme.large.desktop};
  padding-bottom: ${({theme}) => theme.medium.desktop};
  max-width: 40vw;
`;

const Action = style.div`
  ${({theme}) => theme.action.desktop}
  margin-bottom: ${({theme}) => theme.medium.desktop};
  color: ${({theme}) => theme.onSurface};
`;
export default Modal;