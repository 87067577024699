import { Box, BoxOrganization, BoxStatistics } from "../types/type";

export const mapDataToBox = (obj: any): Box => {
    if (obj) {

        const organization: BoxOrganization = {
            id: obj.organization.id,
            name: obj.organization.name
        }

        const statistics: BoxStatistics = {
            nbOfLikes: obj.statistics.nbOfLikes,
            nbOfIdeas: obj.statistics.nbOfIdeas,
            nbOfComments: obj.statistics.nbOfComments
        }

        return obj && {
            id: obj.id,
            name: obj.name,
            description: obj.description,
            summary: obj.summary,
            creationDate: obj.creationDate.toDate(),
            lastUpdateDate: obj.lastUpdateDate.toDate(),
            status: obj.status,
            organization: organization,
            statistics: statistics
        }
    } else {
        throw new Error("impossible to map data to Box: " + obj);
    }
}